<template>
    <div class="homes item">
        <div class="top">
            <div class="left">
				<div class="icon"></div>
				<div class="title">Quartos <span>Parceiros</span></div>
			</div>
            <div class="actions">
                <button :disabled='disableP == true' @click="getHomes(this.prev)"><i class="fa-solid fa-angle-left"></i></button>
                <button :disabled='next >= total' @click="getHomes(this.next)"><i class="fa-solid fa-angle-right"></i></button>
            </div>
        </div>
        <div class="content">
            <div class="box" v-for="q in quartos" :key="q.id">
                <div class="img" :style="{backgroundImage: 'url('+this.uploads+q.img+')'}"></div>
                <a :href="q.link" target="_blank" rel="noopener noreferrer"><div class="title nobr">{{q.nome}}</div></a>
                <div class="dono"><i class="fa-solid fa-building-user"></i>&nbsp;{{q.dono}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import {base, uploads} from '../../../var.js'

export default {
    name: 'ItemHomes',
    data() {
        return {
            quartos: [],
            uploads: uploads,
            prev: 0,
            next: 0,
            disableP: false,
            disableN: false
        }
    },
    methods: {
        async getHomes(paga=1){
            const req = await axios.post(base+'getItens/home', {
                pag: paga
            })
            this.quartos = req.data.homes
            this.prev = req.data.prev
            this.next = req.data.next
            this.total = req.data.total

            if(req.data.prev == 0){this.disableP = true;}else {this.disableP = false;}
			if(req.data.next == req.data.total){this.disableN = true;}else {this.disableN = false;}
        }
    },
    mounted(){
        this.getHomes();
    }
}
</script>

<style scoped>
.homes {
    width: calc(50% - 20px);
}

.homes .top .actions button {
	float: left;
	width: 50px;
	height: 40px;
	background-color: #fff;
	border: 1px solid rgba(0,0,0,.08);
	border-radius: 4px;
	color: rgba(0,0,0,.5);
	font-size: 13px;
	margin-right: 6px;
	cursor: pointer;
	transition: all .4s linear;
}
.homes .top .actions button:hover {background-color: var(--color1);color: #fff;}

.homes .content {
    width: 100%;
    margin-top: 6px;
}

.homes .box {
    float: left;
    width: calc(50% - 20px);
    height: auto;
    background-color: #fff;
    border: 1px solid rgba(0,0,0,.08);
    border-radius: 4px;
    margin: 8px;
    padding: 8px;
}

.homes .box .img {
    float: left;
    width: 60px;
    height: 60px;
    background-position: center;
    background-size: cover;
    border-radius: 4px;
}

.homes .box .title,
.homes .box .dono {
    float: left;
    width: calc(100% - 70px);
    height: 20px;
    color: #444;
    font-size: 14px;
    font-weight: 600;
    margin: 10px 0 0 10px;
}

.homes .box .dono {
    font-weight: 600;
    font-size: 12px;
    opacity: .7;
    margin-top: 6px;
}
</style>