<template>
	<div class="pageCharge">
		<div style="background-image: url('https://i.imgur.com/tpd5Ey6.png')"></div>
	</div>
</template>

<script>

export default {
	name: 'PageCharge',
	props: ['logo'],
	mounted(){
	}
}
</script>

<style scoped>
.pageCharge {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: rgba(0,0,0,.5);
	z-index: 9;
	animation: entrar .2s linear;
}

@keyframes entrar {
	0% {
		top: -100%;
	},
	100% {
		top: 0;
	}
}

.pageCharge div {
	position: relative;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-position: center;
}
</style>