<template>
    <div class="social item">
        <div class="top">
            <div class="left">
				<div class="icon"></div>
				<div class="title">LiveNight <span>Social</span></div>
			</div>
        </div>
        <div class="content">
            <div class="box" v-for="f in post" :key="f.id">
                <div class="topF">
                    <div class="avatar" :style="{backgroundImage: 'url('+this.uploads+f.avatar+')'}"></div>
                    <div class="autor">{{f.autor}}</div>
                    <div class="date">{{f.time}}</div>
                </div>
                <div class="contentF" v-html="f.texto"></div>
                <FeedComment :idre="f.id" :logged="logged" />
            </div>
            <button class="btnMais" v-if="btnMais == true" @click="getMais()">Mostrar mais</button>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import {base, uploads} from '../../../var.js'
import FeedComment from '../feed/feed-comments.vue'

export default {
    name: 'ItemSocial',
    components: {
        FeedComment
    },
    data() {
        return {
            post: [],
            mais: 5,
            uploads: uploads,
			btnMais: false
        }
    },
    methods:{
        async getFeed(){
            const req = await axios.post(base+'getItens/feed', {
				limit: 5
			})
            this.post = req.data.posts
            if(req.data.total > this.mais){
                this.btnMais = true;
            }
        },
        async getMais(){
            let maisp = this.mais + 5;
            const req = await axios.get(base+'getItens/feed', {
				limit: maisp
			})
            this.post = req.data.posts
            if(req.data.total < this.mais){
                this.btnMais = true;
            }
        },
    },
    mounted(){
        this.getFeed();
    }
}
</script>

<style scoped>
.social {
    width: 70%;
}


.social .content {
	position: relative;
	width: 100%;
	height: 304px;
	background-color: #0b1940;
	border-radius: 4px;
	border: 1px solid rgba(0,0,0,.08);
	padding: 14px;
    overflow: auto;
}

.social .content::-webkit-scrollbar{width: 4px;}
.social .content::-webkit-scrollbar-button { display: none; }
.social .content::-webkit-scrollbar-track-piece { background-color: rgba(0,0,0,0.0); }
.social .content::-webkit-scrollbar-thumb { background-color: rgba(0,0,0,0.3); width: 4px; border-radius: 4px;}

.content .box {
	float: left;
	width: 100%;
	height: auto;
	margin-top: 10px;
}
.content .box:first-of-type {margin: 0;}

.content .box .topF .avatar {
	float: left;
	width: 50px;
	height: 50px;
	background-position: center;
	background-size: cover;
	border-radius: 4px;
}

.content .box .topF .autor,
.content .box .topF .date {
	float: left;
	width: 60%;
	height: 20px;
	margin: 4px 0 0 10px;
	font-size: 13px;
	color: var(--color2);
	line-height: 20px;
}
.content .box .topF .date {margin: 0px 0 0 8px;}
.content .box .topF .autor {font-weight: 600;}

.content .box .topF .repost {
	float: right;
	width: auto;
	height: 30px;
	font-size: 13px;
	font-weight: 600;
	text-decoration: underline;
	margin: -20px 10px 0 0;
	cursor: pointer;
}

.content .box .topF .denunc {
	float: right;
	width: 30px;
	height: 30px;
	text-align: center;
	line-height: 30px;
	color: #C20600;
	font-size: 17px;
	margin: 4px -80px 0 0;
}

.content .box .content {
	float: left;
	width: 100%;
	height: auto;
	margin-top: 10px;
}

.content .box .contentF {
	float: left;
	width: 100%;
	height: auto;
	color: var(--color2);
	font-size: 13px;
	margin-top: 10px;
	border-bottom: 1px solid rgba(0,0,0,.08);
	padding: 10px 0;
}

.content .box .contentF >>> img {
	position: relative;
	float: left;
	margin: 10px auto;
	width: auto;
	height: auto;
	max-width: 50%;
	border-radius: 4px;
}

.content .box:last-of-type .contentF {border:0}

.content .btnMais {
	position: relative;
	width: auto;
	height: 34px;
	font-size: 12px;
	font-weight: 600;
	color: #fff;
	background-color: var(--color1);
	border-radius: 20px;
	padding: 0 20px;
	margin: 10px 0 0 50%;
	transform: translateX(-50%);
}
</style>