<template>
	<div class="item last visuais">
		<div class="top">
			<div class="left">
				<div class="icon"></div>
				<div class="title">Últimos <span>Valores</span></div>
			</div>
		</div>
		<div class="content">
			<div class="boxItem toolttip" v-for="m in mobs" :key="m.id" :style="{backgroundImage: 'url('+this.uploads+'valores/icone/'+m.icon}">
				<MyTip :title="m.mob"/>
				<div class="status" :class="{manteve: m.estado == 'manteve', caiu: m.estado == 'caiu', subiu: m.estado == 'subiu'}"></div>
			</div>
		</div>
		<div class="bottom">
			<div class="left">
				<input type="text" name="search" placeholder="Pesquisar..." v-model="this.search"  @change="getMobs()">
				<i class="fa-solid fa-magnifying-glass"></i>
			</div>
			<div class="actions">
				<button :disabled='disableP == true' @click="getMobs(this.prev)"><i class="fa-solid fa-angle-left"></i></button>
				<button :disabled='disableN == true' @click="getMobs(this.next)"><i class="fa-solid fa-angle-right"></i></button>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios'
import { base, uploads } from '../../../var.js'
import MyTip from '../usefulness/tooltip.vue'

export default {
	name: 'LastValo',
	components: {
		MyTip
	},
	data() {
		return {
			mobs: [],
			uploads: uploads,
			statusMob: '',
			prev: 0,
			next: 0,
			disableP: false,
			disableN: false,
			search: ''
		}
	},
	methods: {
		async getMobs(paga=1){
			const req = await axios.post(base+'getItens/mobs-val', {
				limit: 21,
				pag: paga,
				s: this.search
			})
			this.mobs = req.data.valores
			this.prev = req.data.prev
			this.next = req.data.next

			if(req.data.prev == 0){this.disableP = true;}else {this.disableP = false;}
			if(req.data.next == req.data.total){this.disableN = true;}else {this.disableN = false;}
		}
	},
	mounted(){
		// this.getMobs(1)
	}
}
</script>

<style scoped>
.last {
	width: calc(33% - 8px);
}

.last .bottom {
	position: relative;
	float: left;
	width: 100%;
	height: 40px;
	display: flex;
	justify-content: space-between;
	margin-top: 10px;
}

.last .bottom .left {width: 55%;}

.last .bottom .left input {
	width: 100%;
	height: 100%;
	background-color: #0b1940;
	border-radius: 4px;
	border: 1px solid #0b1940;
	padding: 0 10px 0 30px;
	font-size: 13px;
	color: #fff;
}

.last .bottom .left i {
	position: absolute;
	top: 5px;
	left: 2px;
	width: 30px;
	height: 30px;
	line-height: 30px;
	text-align: center;
	font-size: 14px;
	color: #fff;
}

.last .bottom .actions button {
	float: left;
	width: 70px;
	height: 40px;
	background-color: #0b1940;
	border: 1px solid rgba(0,0,0,.08);
	border-radius: 4px;
	color: rgba(0,0,0,.5);
	font-size: 13px;
	margin-right: 6px;
	cursor: pointer;
	transition: all .4s linear;
}
.last .bottom .actions button:hover {background-color: var(--color1);color: #fff;}

.last .content {
	position: relative;
	float: left;
	width: 100%;
	height: 188px;
	z-index: 0;
}

.last .content {
	float: left;
	width: 100%;
	height: auto;
	background-color: #0b1940;
	border-radius: 4px;
	border: 1px solid rgba(0, 0, 0, 0.08);
	padding: 4px;
}

.last .boxItem {
	float: left;
	position: relative;
	width: 46px;
	height: 46px;
	background-color: rgba(0, 0, 0, 0.1);
	background-position: center;
	background-repeat: no-repeat;
	border-radius: 4px;
	margin: 4px;
}

.last .boxItem .status {
	float: right;
	width: 14px;
	height: 14px;
	margin: 36px -6px 0 0;
	background-repeat: no-repeat;
	background-position: center;
}
.last .boxItem .status.manteve {background-image: url('../../assets/img/icon24.png');}
.last .boxItem .status.caiu {background-image: url('../../assets/img/icon23.png');}
.last .boxItem .status.subiu {background-image: url('../../assets/img/icon25.png');}

</style>