<template>
	<div class="footer">
		<div class="between">
			<div class="copy">
				<span>LiveNight • {{this.date}}</span><br>
				<span>Todos os direitos reservados.</span>
			</div>
			<div class="about">A LiveNight é um fã-site oficial do Habblive Hotel, não está afiliada com, patrocinada por, apoiada por, ou principalmente aprovada pela Habblive. Nosso intuito é apenas dar informações e entretenimento a comunidade do hotel.
				Esta Fã Site pode utilizar as marcas registradas e outras propriedades intelectuais do Habblive, que estão permitidas sob a Política de Fã Sites Habblive.<br>
				Fundado e operado por Guilherme Abdala e Inayra</div>
			<a href="https://www.Habblive.in/" target="_blank">
				<div class="logoHabblet" :style="{backgroundImage: 'url('+this.uploads+'temas/'+this.logof+')'}"></div>
			</a>
		</div>
		<div class="backDev">
			<div class="between">
				<div class="back">Voltar para o topo <i class="fa-solid fa-sort-up"></i></div>
				<div class="dev">Esse site foi desenvolvido por <a href="https://twitter.com/WurxHabbo" target="_blank" rel="noopener noreferrer">Luan Wieyra</a> e  <a href="https://twitter.com/ZiniiBr" target="_blank" rel="noopener noreferrer">ZiniiBr</a></div>
			</div>
		</div>
	</div>
</template>

<script>
import {uploads} from '../../var.js'

export default {
	name: 'itemFooter',
	data() {
		return {
			date: new Date().getFullYear(),
			uploads: uploads
		}
	},
	props: ['logof'],
	methods: {
		scrol() {
			const btn = document.querySelector(".back");
			btn.addEventListener("click", function() {
				window.scrollTo({
					top: 0,
					left: 0,
					behavior: 'smooth'
				});
			});
		}
	},
	mounted: function() {
		this.scrol()
	}
}
</script>

<style scoped>

.footer {
	position: relative;
	float: left;
	top: 125px;
	width: 100%;
	height: auto;
	background-color: #0b1940;
	z-index: 0;
}

.footer .between {
	width: var(--width);
	height: auto;
	display: flex;
	justify-content: space-between;
	margin: 0 auto;
}

.footer .copy {
	width: 40%;
	height: auto;
	font-size: 13px;
	color: var(--color2);
	font-weight: 600;
	padding: 10px 0;
	text-align: center;
	align-self: center;
	border-right: 1px solid rgba(255, 255, 255, .5);
	margin-top: 30px;
}

.footer .copy span:last-of-type {opacity: .7;}

.footer .about {
	width: 50%;
	height: auto;
	padding: 20px;
	color: var(--color2);
	font-size: 12px;
	margin-top: 30px;
}

.footer .logoHabblet {
	width: 78px;
	height: 78px;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	/* filter: grayscale(1); */
	/* opacity: .6; */
	margin-top: 40px;
	transition: all .3s ease;
}
/* .footer .logoHabblet:hover {opacity: 1;filter: grayscale(0);} */

.footer .backDev {
	width: 100%;
	height: auto;
	padding: 10px;
	background-color: var(--color1);
}

.footer .backDev .back {
	color: #fff;
	font-size: 12px;
	font-weight: 600;
	line-height: 20px;
	transition: all .2s linear;
	margin-left: 170px;
}

.footer .backDev .back:hover {color: #ccc;}

.footer .backDev .back i {margin: 7px 4px 0 0;float: left;}

.footer .backDev .dev {
	font-size: 12px;
	color: #fff;
	font-weight: 600;
	opacity: .7;
}

.footer .backDev .dev a {opacity: 1;}

</style>