<template>
<div>
	<div class="main" v-if="this.maintenance == 'false' || this.maintenance == false">
		<ItemHeader :header="this.header" :logo="this.logo"/>
		<router-view :key="this.$route.fullPath"/>
		<itemFooter :logof="this.logo_footer"/>
	</div>
	<div class="manu" v-else>
		<div class="center">
			<div class="logo"><img src="https://i.imgur.com/tpd5Ey6.png"></div>
			<div>
				
				<div class="icon">
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-gear-fill" viewBox="0 0 16 16">
						<path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"/>
					</svg>
				</div>
				<div class="texto2"><b>MANUTENÇÃO!</b></div>
				<div class="texto"><b>Mas,</b><p>enquanto isso, você pode escutar nossas programações normalmente!</p></div>
			</div>
			<audio id="audio" autoplay controls hidden src="https://stream2.svrdedicado.org:7066/stream"></audio>
		</div>
	</div>
</div>
</template>

<script>
import ItemHeader from './components/header.vue'
import itemFooter from './components/footer.vue'
import './assets/css/pace.css'
import axios from 'axios'
import { base,uploads } from '../var.js'

export default {
	components: {
		ItemHeader,
		itemFooter
	},
	data(){
		return {
			color1: '',
			color2: '#444',
			logo: '',
			header: '',
			logo_footer: '',
			fav: '',
			root: document.documentElement,
			maintenance: false,
			uploads: uploads
		}
	},
	methods: {
		async getInit(){
			const req = await axios.get(base+'getItens/init')
			this.color1 = req.data.color1
			this.color2 = req.data.color2
			this.logo = req.data.logo
			this.logo_footer = req.data.logo_footer
			this.header = req.data.header
			this.favicon = req.data.favicon
			this.locutorOfc = req.data.radioUser
			this.maintenance = req.data.maintenance

			this.root.style.setProperty("--color1", this.color1);
			this.root.style.setProperty("--color2", this.color2);

			var link = document.querySelector("link[rel*='icon']") || document.createElement("link");
			link.type = "image/x-icon";
			link.rel = "shortcut icon";
			link.href = uploads+'/temas/'+this.favicon;
			document.getElementsByTagName("head")[0].appendChild(link);
		}
	},
	created(){
		this.getInit();
	}
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
	font-family: 'Montserrat', sans-serif;
	background-color: #02081e;
}

* {padding: 0;margin: 0;box-sizing: border-box;}

button,textarea,li,ul,select,input,a,hr {border:0;list-style: none;background-color: transparent;outline: none;color: inherit;text-decoration: none;font-family: 'Montserrat', sans-serif;}
input[type=radio] {display: none;}
label {width: 100%;float: left;}

.nobr {white-space: nowrap;text-overflow: ellipsis;overflow: hidden;}

#app, .main {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: auto;
	/* background-image: url('assets/img/body.png'); */
}

:root {
	--width: 1200px;
	--color1: #02081e;
	--color2: #fff;
}

/* .header .iconhc {background-image: url('assets/img/hc.png');} */
/* .header .icon1 {background-image: url('assets/img/icon1.png');}
.header .icon2 {background-image: url('assets/img/icon2.png');} */
nav .icon3 {background-image: url('assets/img/icon3.png');}
/* nav .icon4 {background-image: url('assets/img/icon4.png');} */
/* nav .icon5 {background-image: url('assets/img/icon5.png');} */
nav .icon6 {background-image: url('assets/img/icon6.png');}
/* nav .icon7 {background-image: url('assets/img/icon7.png');} */
nav .icon8 {background-image: url('assets/img/icon8.png');}
nav .icon9 {background-image: url('assets/img/icon33.png');opacity: .6;}
nav .icon10 {background-image: url('assets/img/icon10.png');}
nav .icon11 {background-image: url('assets/img/icon32.gif');}
.news .top .icon {background-image: url('assets/img/icon12.png');}
.radios .top .icon {background-image: url('assets/img/icon13.png');}
.tags .top .icon {background-image: url('assets/img/icon14.png');}
.social .top .icon,
.feed .top .icon {background-image: url('assets/img/icon15.png');}
.slide .top .icon {background-image: url('assets/img/icon16.png');}
.homes .top .icon {background-image: url('assets/img/icon17.png');}
.last.mobs .top .icon {background-image: url('assets/img/icon18.png');}
.last.badges .top .icon {background-image: url('assets/img/icon19.png');}
.last.visuais .top .icon {background-image: url('assets/img/icon20.png');}

.container {
	width: 100%;
	position: relative;
	display: flex;
	justify-content: center;
	z-index: 0;
}

.container .between {
	width: var(--width);
	display: flex;
	justify-content: space-between;
}

@keyframes slider {
	0% {
		height: 0;
	}
	100% {
		height: auto;
	}
}

@keyframes enter {
	0% {
		margin-right: -10px;
		opacity: .2;
	}
	100% {
		margin: 0;
		opacity: 1;
	}
}

.item {
	position: relative;
	float: left;
	width: var(--width);
	height: auto;
	margin-top: 30px;
	z-index: 0;
}

.item .top {
	float: left;
	width: 100%;
	height: 60px;
	display: flex;
	justify-content: space-between;
}

.item .top button[disabled],
.page .top button[disabled] {opacity: .5;}

.item .top .icon {
	float: left;
	width: 44px;
	height: 44px;
	background-color: rgba(0,0,0,.1);
	border-radius: 40px;
	background-position: center;
	background-repeat: no-repeat;
}

.item .top .title {
	float: left;
	width: auto;
	height: 20px;
	font-size: 15px;
	color: #fff;
	margin: 14px 0 0 10px;
}

.item .top .title span {font-weight: 600;}

.item .content {
	float: left;
	margin-top: 10px;
}

.page {
	position: relative;
	width: var(--width);
	height: auto;
	margin: 0 auto;
	top: 30px;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.buttonM {display: none;}

@media only screen and (max-width: 500px) {
	.header .top {width: 100% !important;float: left;display: block;}
	.header .topHeader {height: 170px !important;}
	.player {display: block !important;width: 100% !important;}
	.player .boxLoc {float: left !important;position: absolute !important;left: 40px;}
	.player .boxAvatar {margin-top: 82px;float: left;}
	.player .play {position: absolute !important;}
	.player .volume {float: left;margin: 92px 0 0 0;}
	.player .ouvm {float: left;top: 88px;left: 215px;margin: 0;}
	.mobileNav {
		position: absolute !important;
		display: block !important;
		width: 100% !important;
		height: 100% !important;
		top: 0;
		left: 0;
		margin: 0 !important;
		z-index: 9;
	}
	.buttonM {
		display: block !important;
		position: absolute;
		top: 184px;
		left: 10px;
		width: 40px;
		height: 40px;
		background-color: #fff;
		border-radius: 4px;
		border: 1px solid rgba(0,0,0,.1);
		z-index: 99;
	}
	.buttonM.active {top: 10px;}
	.mobileNav .navM {display: block !important;}
	.mobileNav .mainNav {display: block !important;}
	nav {display: none !important;}
	.mobileNav ul .mainLi {width: 100% !important;}

    .boxLogin {
		position: absolute;
		top: 160px;
		left: 72px;
		z-index: 5;
		width: 80% !important;
	}

	.item {width: 100%;}
	.item .top {display: block;padding: 0 10px;}
	.item .top .left {width: 100%;float: left;}
	.homes .top .left {width: 70%;}
	.item .top .actions {width: 100%;float: left;display: flex;justify-content: space-between;margin-top: 4px;}
	.homes .top .actions {width: 30%;}
	.last .bottom { padding: 0 10px;}
	.last .bottom .left i {left: 10px !important;}
	.news .top {height: 90px;}
	.news .top .actions .select {width: 140px !important;}
	.news .top .actions input {width: 160px !important;}
	.item .content {width: calc(100% - 20px) !important;}
	.container .between {display: block !important;width: 100%;}
	.container .between .content {width: calc(100% - 20px) !important;margin: 10px;}
	.news .boxFixeds,
	.news .boxNormal {display: block !important;}
	
	.news .box,
	.news .boxf,
	.radios .box {width: 100% !important;margin: 8px 10px;}

	.radios .content {display: block !important;}

	.tags,
	.social,
	.slide,
	.homes,
	.last {width: 100% !important;}

	.footer .between {width: 100% !important;}
	.footer .copy {width: 30% !important;}

	.page {display: block !important; width: calc(100% - 20px) !important;margin-left: 10px;}
	.page .left,
	.page .right {width: 100% !important;}
	.page .right {margin-top: 20px;float: left;}

	.page .reading img {max-width: 100%;}
	.news .boxInfo {float: left;}

	.comments .content .box {width: calc(100% - 20px) !important;}

	.content .mobs .mob {width: calc(50% - 10px) !important;margin-left: 5px !important;}

	.valores .content .categoria {margin-top: 10px;float: left;}
	.categoria .cat {margin: 0 5px 0 0 !important;}

	.page .feed {float: left !important; width: 100% !important;}

	.feed .top {height: 80px !important;}
	.page .top .actions {width: 100% !important;margin-top: 6px;}

	.modal .box {width: calc(100% - 10px) !important;}

	.news .reading >>> .tarja {background-size: contain;}

	.footer .backDev .back {width: 46%; margin: 6px 20px 0 0 !important;}
}

/* Manutenção */
.manu {
	position: fixed;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-image: linear-gradient(to bottom, #FF9D1A, #FD8F0B);
	font-family: 'Montserrat', sans-serif;
}

.manu .center {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 40%;
	height: auto;
	transform: translate(-50%, -50%);
}
        
.manu .logo {
	float: left;
	width: 300px;
	height: 300px;
	font-size: 120px;
	color: #FFF;
	margin-right: 100px;
	position: relative;
	z-index: 5;
}

.manu .texto {
	float: left;
	margin: 50px 0 0 0;
	color: #FFF;
	width: 300px;
	font-size: 20px;
	position: relative;
	z-index: 5;
}
.manu .texto2 {
	float: left;
	color: #FFF;
	width: 200px;
	font-size: 26px;
	position: absolute;
	z-index: 5;
	margin-top: -10%;
	margin-left: 33%;
}
.manu .icon {
	position: absolute;
	float: left;
	width: 16px;
	height: 16px;
	color: #FFF;
	width: 40px;
	z-index: 5;
	margin: -9% 0 0 20%;
}


.manu #playAudio {
	float: left;
	right: 0;
	width: 120px;
	height: 45px;
	border-radius: 100px;
	border-color: #ffffff;
	box-shadow: 0px;
	color: #ff9d1a;
	background-color: #ffffff;
	text-decoration: none;
	font-family: 'Montserrat', sans-serif;
	float: left;
	padding: 0 0px;
	margin-top: 40px;
	margin-left: 240px;
	border: 0;
	box-shadow: 0px -1px 10px 0px rgb(0 0 0 / 0%);
	z-index: 5;
}
.manu > div {width: 400px;}

.manu .vector {
	float: left;
	width: auto;
	display: flex;
	align-items: center;
	height: 40px;
	float: left;
	border-radius: 100px;
	margin-right: -200px;
	margin-top: 70px;
	position: relative;
	z-index: 5;
}

</style>