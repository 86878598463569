<template>
    <div class="page">
        <div class="top">
            <div class="title">{{this.title}}</div>
        </div>
        <div class="content">
            <div class='centraliza'>
                <div class='esquerda'>
                    <div class='titulo'>Habbo:</div>
                    <input id="usuario" placeholder="Vaguinho" name="user" type="text" v-model="nick" @change="imager()">
                
                    <div class='clearfix'></div>
                
                    <div class='titulo'>Rosto:</div>
                    <select id="rosto" name="rosto" v-model="rosto" @change="imager()">
                        <option value="std">Normal</option>
                        <option value="spk">Falando</option>
                        <option value="sml">Sorrindo</option>
                        <option value="srp">Surpreso</option>
                        <option value="agr">Nervoso</option>
                        <option value="sad">Triste</option>
                        <option value="lol">Sem Rosto</option>
                    </select>
                
                    <div class='clearfix'></div>
                
                    <div class='titulo'>Ação:</div>
                    <select id="acao" name="acao" v-model="acao" @change="imager()">
                        <option value="std">Normal</option>
                        <option value="wlk">Andando</option>
                        <option value="wlk,wav">Andando/Acenando</option>
                        <option value="sit">Sentado</option>
                        <option value="sit,wav">Sentado/Acenando</option>
                        <option value="lay">Deitado</option>
                        <option value="wav">Acenando</option>
                    </select>
                
                    <div class='clearfix'></div>
                
                    <div class='titulo'>Objeto:</div>
                    <select id="objeto" name="objeto" v-model="item" @change="imager()">
                        <option value="">Nenhum</option>
                        <option value="21">Objeto imaginário</option>
                        <option value="1">Café</option>
                        <option value="53">Café Expresso</option>
                        <option value="2">Água</option>
                        <option value="62">Água Envenenada</option>
                        <option value="54">Chocolate Quente</option>
                        <option value="3">Cenoura</option>
                        <option value="4">Sorvete de Baunilha</option>
                        <option value="25">Poção do Amor</option>
                        <option value="26">Calippo</option>
                        <option value="28">Sake</option>
                        <option value="29">Suco de Tomate</option>
                        <option value="30">Líquido Radioativo</option>
                        <option value="31">Drink Tropical</option>
                        <option value="34">Peixe</option>
                        <option value="36">Pêra</option>
                        <option value="40">Suco de Laranja</option>
                        <option value="42">Laranja Efervescente</option>
                        <option value="39">Abacaxi</option>
                        <option value="48">Pirulito</option>
                        <option value="58">Sangue Fresco</option>
                        <option value="59">Saco</option>
                        <option value="60">Castanhas</option>
                        <option value="61">Refrigerante Ensolarado Laranja</option>
                        <option value="63">Saco de Pipocas</option>
                        <option value="64">Líquido Radioativo</option>
                        <option value="70">Coxa de Frango</option>
                        <option value="71">Torrada</option>
                    </select>
                
                    <div id="bb" :class="{active: item != ''}" >
                        <input type="checkbox" name="bbcc" id="bbcc" value=",drk=" v-model="seg" @change="imager()">
                        <div class='titulo' style='width: 215px; line-height: 30px; text-align: left; float: right;'>Bebendo/Comendo</div>				
                    </div>				
                        
                    <div class='clearfix'></div>
                    
                    <div class='titulo'>Tamanho:</div>
                    
                    <div class='tamanhos' style='width: 165px; margin-bottom: 17px;'>
                        <label>
                            <input type="radio" name="tamanho" id="tamanho" value="s" v-model="tamanho" @change="imager()">
                            <div class='titulo tmais'>Pequeno</div>
                        </label>
                        <label>
                            <input type="radio" checked name="tamanho" id="tamanho" value="b" v-model="tamanho" @change="imager()">
                        <div class='titulo tmais'>Médio</div>
                        </label>
                        <label>
                            <input type="radio" name="tamanho" id="tamanho" value="l" v-model="tamanho" @change="imager()">
                        <div class='titulo tmais'>Grande</div>
                        </label>
                    </div>
                    <div class='clearfix'></div>
                    
                    <div class='titulo'>URL:</div>
                    <input type="text" id="url_img" :value="this.url">
                </div>

                <div class='direita'>
        
                    <div class='clearfix'></div>
                    <div class='fundo-user'>
                        <a id="img_link" target="_blank" :href="this.url">
                            <div class="avatar">
                                <img alt="" :src="this.url" @change="imager()" id="img">
                            </div>
                        </a>
                    </div>
                    <div class='menu-girar' style='padding: 14px 10px; margin-top: 5px;'>
                        <div class='so-cabeca'></div>
                        <input type="checkbox" name="cabeca" id="cabeca" value="1" style='margin-left: 5px;' v-model="soH" @change="imager()">
                    </div>
                    <div class='menu-girar'>
                        <div class='girar'>
                            <div id="h_l" class='seta-box' @click="imager(this.dirH + 1, this.dir)" @change="imager()"><i class='fa fa-angle-left'></i></div>
                            <div class='cabeca'></div>
                            <div id="h_r" class='seta-box' @click="imager(this.dirH - 1, this.dir)" @change="imager()"><i class='fa fa-angle-right'></i></div>
                        </div>

                        <div class='clearfix'></div>
                        <div class='girar'>
                            <div id="b_l" class='seta-box' @click="imager(this.dirH, this.dir + 1)" @change="imager()"><i class='fa fa-angle-left'></i></div>
                            <div class='corpo'></div>
                            <div id="b_r" class='seta-box' @click="imager(this.dirH, this.dir - 1)" @change="imager()"><i class='fa fa-angle-right'></i></div>
                        </div>
                    </div>

                    <select id="formato" class='formato' name="formato">
                        <option value="png">PNG</option>
                        <option value="gif">GIF</option>
                    </select>

                    <div class='clearfix'></div>
                </div>
            </div>
            <span class="credits">Gerador de Habbo Imager feito por Marco cruel.<br>JavaScript modificado para o VueJS por ZiniiBr</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Imager',
    data(){
        return {
            title: 'Habbo Imager',
            dir: 4,
			dirH: 4,
			hotel: 'com.br',
			nick: 'Zinii',
			item: '',
			acao: 'std',
			tamanho: 'b',
			rosto: 'std',
			action: '',
			soH: false,
			formato: '',
			seg: false,
            frame: 30,
            effect: '',
            dance: '',
			url: 'https://imager.livenight.net/?user=Vaguinho&action=std&direction=4&head_direction=4&img_format=png&gesture=std&headonly=0&size=b'
		}
    },
    methods: {
		imager(direH = 4, dire = 4){
			if(this.item == 0) {
				this.action = this.item;
			} else if (this.seg == true) {
				this.action = this.acao+",drk="+this.item;
			} else {
				this.action = this.acao+",crr="+this.item;
			}

			if (direH < 7) this.dirH = direH;
			if (direH > 0) {this.dirH = direH}else{this.dirH = 0}
			if (dire < 7) this.dir = dire;
			if (dire > 0) {this.dir = dire;}else{this.dir = 0;}
			this.url = `https://imager.livenight.net/?user=${this.nick}&action=${this.action}&gesture=${this.rosto}&direction=${this.dir}&head_direction=${this.dirH}&headonly=${this.soH}&size=${this.tamanho}&img_format=${this.formato}&dance=${this.dance}&effect=${this.effect}&frame_num=${this.frame}`;
		}
	},
	mounted: function() {
		document.title = 'Habblive Imager • LiveNight';
		this.imager();
	},
}
</script>

<style scoped>
.page {
    display: block;
    margin-top: 30px;
}

.page .top {
    width: 100%;
    height: 50px;
    line-height: 50px;
    color: #fff;
    border-radius: 4px 4px 0 0;
    padding-left: 20px;
    font-size: 13px;
    font-weight: 600;
}

.page .content {
    float: left;
    width: 100%;
    height: auto;
    background-color: #fff;
    border-radius: 8px;
    padding: 10px;
    font-size: 13px;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
}

.clearfix {clear: both;}:focus {outline: 0;}
.centraliza {width: 590px;height: 270px; position: relative;top: 50%;margin-top: 10px;left: 50%;margin-left: -295px;image-rendering: pixelated;}
.centraliza input, select {background-color: transparent;width: 200px;height: 30px;padding: 0 10px;margin: 5px;border: none;border-radius: 2px;box-shadow: 0 0 0 1px rgba(0,0,0,0.15);color: #999;-webkit-appearance: none;font-family: 'Ubuntu', sans-serif;}
.centraliza input[type="text"] {width: 180px;}
.centraliza select {background-image: url(https://1.bp.blogspot.com/-Lrc_o4XSWN4/VwsMr_2sf3I/AAAAAAAAAyk/yJaqrrqDOkooUiu-EOqt6Exx4eFu_4fXw/s1600/seta.png);background-repeat: no-repeat;background-position: 176px center;overflow: hidden; position: relative;}
.centraliza input[type="radio"],
.centraliza input[type="checkbox"] {width: 20px;height: 20px;border-radius: 10px;cursor: pointer;display: inline-block;margin-left: 27px;position: relative;-webkit-appearance: none;-moz-appearance: none;appearance: none;}
.centraliza input[type="checkbox"] {border-radius: 2px;padding: 0 5px;}
.centraliza input[type="radio"]:after,
.centraliza  input[type="checkbox"]:after {width: 12px;height: 12px;border-radius: 10px;content: '';display: block;top: 4px;left: -1px;position: relative;}
.centraliza input[type="checkbox"]:after {border-radius: 1px;}
.centraliza input[type="radio"]:checked:after,.centraliza input[type="checkbox"]:checked:after {background-color: #999;}
.tamanhos input[type="radio"] {margin-left: 16px;margin-right: 11px;width: 15px;height: 15px;padding: 0 5px;}
.tamanhos input[type="radio"]:after {width: 7px;height: 7px;}
.esquerda {width: 280px;position: relative;float: left;}
.direita {width: 315px;position: relative;float: left;margin-left: -10px;}
.titulo {width: 60px;line-height: 40px;color: #999;float: left;text-align: center;font-size: 16px;}
.tmais {width: 46px;line-height: 10px;font-size: 10px;text-align: center;margin-bottom: 9px;}
.tamanhos {width: 185px;float: right;margin: 6px 8px;}
.link {position: absolute;bottom: 10px;}
.hotelImager {background-image: url(https://3.bp.blogspot.com/-8hffUDZi8_c/VslIU_GfKTI/AAAAAAAAEaU/faTD3sYkUsU/s1600/hotel.png), url(https://1.bp.blogspot.com/-Lrc_o4XSWN4/VwsMr_2sf3I/AAAAAAAAAyk/yJaqrrqDOkooUiu-EOqt6Exx4eFu_4fXw/s1600/seta.png);background-repeat: no-repeat;background-position: 10px center, 176px center;padding-left: 37px;}
.fundo-user {background-position: center;width: 200px;height: 220px;float: left;margin: 5px;border-radius: 2px;box-shadow: 0 0 0 1px rgba(0,0,0,0.15);}
.avatar {width: 200px;height: 220px;position: relative;}
.avatar img {position: absolute;margin: auto;top: 0;left: 0;right: 0;bottom: 20px;}
.menu-girar {padding: 10px;width: 80px;float: right;border-radius: 2px;margin: 5px 0;box-shadow: 0 0 0 1px rgba(0,0,0,0.15);text-align: center;font-size: 14px;color: #999;}
.girar {width: 80px;height: 25px;}
.seta-box {width: 15px;height: 26px;padding: 5px; padding-top: 3px;padding-bottom: 7px;float: left;border-radius: 2px;box-shadow: inset 0 0 0 1px rgba(0,0,0,0.15);transition: all 0.15s;color: #9F9F9F;}
.seta-box:hover {background-color: rgba(0,0,0,0.15);color: #FFF;}
.girar .cabeca {background-image: url(https://1.bp.blogspot.com/-WsBLUqV4J3E/VslIiBgiuaI/AAAAAAAAEaY/wL3J5J7orFE/s1600/cabeca.png);background-repeat: no-repeat;background-position: center;width: 30px;height: 25px;margin-bottom: 10px;float: left;}
.girar .corpo {background-image: url(https://4.bp.blogspot.com/-rjclYO-iAHo/VslIiMDDQLI/AAAAAAAAEac/fyZLZ8y10TI/s1600/corpo.png);background-repeat: no-repeat;background-position: center;width: 30px;height: 25px;float: left;}
.formato {background-position: 76px center;width: 100px;margin-right: 0;}
.so-cabeca {background-image: url(https://4.bp.blogspot.com/-MqlEG2UdvEw/VzsRBhGEvvI/AAAAAAAAFKA/BH6MiiZzT14xlT4756a0wsezwvMkYPTawCLcB/s1600/so-cabeca.png);width: 14px;height: 19px;float: left;margin-left: 15px;margin-top: 6px;margin-right: -5px;}

#bb {display: none;}
#bb.active {
	display: block;
}

.credits {
    width: auto;
    height: auto;
    float: right;
    font-size: 13px;
    font-weight: 300;
    opacity: .7;
    font-style: italic;
}

</style>