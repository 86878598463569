import { createRouter, createWebHistory } from 'vue-router'
import ItemInicio from '../views/home.vue'
// import PagNews from '../views/newspage.vue'
import Error from '../views/error.vue'
import PagePagina from '../views/pagina.vue'
import Imager from '../views/imager.vue'
import PageFeed from '../views/feed.vue'
import PageVal from '../views/valores.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: ItemInicio
  },
  {
    path: '/news/:id/:url',
    name: 'News',
    component: () => import(/* webpackChunkName: "noticia" */ '../views/newspage.vue'),
    props: true
  },
  {
    path: '/pagina/imager',
    name: 'imager',
    component: Imager
  },
  {
    path: '/pagina/:id',
    name: 'pagina',
    component: PagePagina,
    props : true
  },
  {
    path: '/error',
    name: 'Error',
    component: Error
  },
  {
    path: '/feed',
    name: 'Feed',
    component: PageFeed
  },
  {
    path: '/valores',
    name: 'Valores',
    component: PageVal
  },
  {
    path: '/api',
    name: 'PageApi',
    component: () => import(/* webpackChunkName: "pagina-api" */ '../views/pageapi.vue'),
  },
  {
    path: '/profile-api',
    name: 'profile-api',
    component: () => import(/* webpackChunkName: "profile-api" */ '../views/pageProfileApi.vue'),
  },
  {
    path: '/equipe',
    name: 'team',
    component: () => import(/* webpackChunkName: "team" */ '../views/team.vue'),
  },
  {
    path: '/horarios',
    name: 'horarios',
    component: () => import(/* webpackChunkName: "horarios" */ '../views/horarios.vue'),
}
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  }
})

export default router
