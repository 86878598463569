<template>
	<div class="modal">
		<div class="box">
			<div class="top">
				<div class="title">Fazer <strong>Login</strong></div>
				<div class="close" @click="$emit('close')">X</div>
			</div>
			<div class="content">
				<form @submit="login">
					<div class="boxInput">
						<span>Usuário(a):</span>
						<input type="text" name="nick" placeholder="Digite sei nick..." v-model="user">
					</div>
					<div class="boxInput">
						<span>Senha:</span>
						<span class="link" @click="$emit('forgot')"><strong>Esqueci</strong> a senha!</span>
						<input type="password" name="pass" placeholder="Digite sua senha..." v-model="pass">
					</div>
					<button>ENTRAR</button>
				</form>
			</div>
		</div>
	</div>
	<Transition name="slide-fade">
	<alerta v-if="text != ''" :text="text"></alerta>
	</Transition>
</template>

<script>
import axios from 'axios'
import {base} from '../../../var.js'
import Alerta from '../usefulness/alert.vue'

export default {
	name: 'ModLogin',
	components: {
		Alerta
	},
	data() {
		return {
			user: '',
			pass: '',
			error: false,
			text: ''
		}
	},
	methods: {
		async login(e) {
			e.preventDefault();
			const req = await axios.post(base+'page/login', {
				user: this.user,
				pass: this.pass
			});
			this.error = req.data.error

			this.text = req.data.text
			setTimeout(() => {
                this.text = ''
            }, 3000);

			if(req.data.reloadAll){
				document.location.reload(true);
			}
		}
	}
}
</script>

<style scoped>

.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 999;
	background-color: rgba(0,0,0,.3);
	transition: enter .3s linear;
}

.modal .box {
	position: absolute;
	width: 550px;
	height: auto;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.modal .top {
	float: left;
	width: 100%;
	height: 50px;
	background-color: var(--color1);
	border-radius: 4px 4px 0 0;
	color: #fff;
	font-size: 13px;
	line-height: 50px;
	padding: 0 20px;
}

.modal .top .close {
	position: absolute;
	top: -15px;
	right: -10px;
	width: 30px;
	height: 30px;
	background-color: #fff;
	border-radius: 30px;
	border: 1px solid var(--color1);
	color: var(--color1);
	text-align: center;
	line-height: 30px;
	font-size: 12px;
	font-weight: 800;
	user-select: none;
}

.modal .content {
	float: left;
	width: 100%;
	height: auto;
	background-color: #fff;
	border-radius: 0 0 4px 4px;
	box-shadow: 0 0 8px rgba(0,0,0,.1);
	padding: 10px;
	font-size: 13px;
}

.modal .content .boxInput {
	float: left;
	width: 100%;
	height: auto;
	margin: 15px 0 0 0;
}

.modal .content .boxInput span {
	width: 100%;
	height: 20px;
	font-weight: 600;
}

.modal .content .boxInput span.link {
	width: auto;
	float: right;
	font-weight: 400;
}

.modal .content .boxInput input {
	float: left;
	width: 100%;
	height: 45px;
	padding: 0 14px;
	border: 1px solid rgba(0,0,0,.1);
	border-radius: 4px;
	margin-top: 6px;
}
.modal .content .boxInput input::placeholder {font-size: 12px;}

.modal .content button {
	float: left;
	width: 100%;
	height: 50px;
	color: #fff;
	background-color: var(--color1);
	border-radius: 4px;
	font-size: 13px;
	font-weight: 600;
	margin-top: 16px;
}
</style>