<template>
	<div class="page valores">
		<div class="top">
			<div class="title">Valores</div>
		</div>
		<div class="content">
			<div class="actions">
				<input type="text" name="search" placeholder="Pesquisar mobs..." v-model="search" @change="getMobs()">
				<button class="search"><i class="fa-solid fa-magnifying-glass"></i></button>

			</div>
			<div class="categoria">
				<div class="cat" :class="{active: activeCat == 'todas'}" @click="getMobs(0), this.activeCat = 'todas'">
					<span>Todas as categorias</span>
				</div>
				<div class="cat" :class="{active: activeCat == v.category}" v-for="v in val_cat" :key="v.id" @click="getMobs(v.id), this.activeCat = v.category">
					<div class="icon" :style="{backgroundImage: 'url('+this.uploads+'valores/categoria/'+v.icon+')'}"></div>
					<span>{{v.category}}</span>
				</div>
			</div>
			<div class="mobs">
				<div class="mob toolttip" v-for="m in mobs" :key="m.id">
					<Mytip :title="m.preco+' moedas'" />
					<div class="icon" :style="{backgroundImage: 'url('+this.uploads+'valores/icone/'+m.icon}">
						<div class="status" :class="{manteve: m.estado == 'manteve', caiu: m.estado == 'caiu', subiu: m.estado == 'subiu'}"></div>
					</div>
					<div class="name nobr">{{m.mob}}</div>
					<div class="att">Atualizado em:<br> {{m.time}}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios'
import {base, uploads} from '../../var.js'

import Mytip from '../components/usefulness/tooltip.vue'

export default {
	name: 'PageVal',
	components: {
		Mytip
	},
	data() {
		return {
			mobs: [],
			val_cat: [],
			search: '',
			uploads: uploads,
			activeCat: 'todas',
			statusMob: ''
		}
	},
	methods: {
		async getCat() {
			const req = await axios.get(base+'getItens/val-cat')
			this.val_cat = req.data
		},
		async getMobs(id){
			const req = await axios.post(base+'getItens/mobs-val', {
				id: id,
				s: this.search
			})
			this.mobs = req.data.valores
		}
	},
	mounted(){
		this.getCat();
		this.getMobs(0)
	}
}
</script>

<style scoped>
.page {
	margin-top: 30px;
}

.page .top {
	width: 100%;
	height: 50px;
	line-height: 50px;
	color: #fff;
	padding-left: 20px;
	font-size: 13px;
	font-weight: 600;
}

.page .content {
	float: left;
	width: 100%;
	height: auto;
	background-color: #fff;
	border-radius: 8px;
	padding: 10px;
	font-size: 13px;
	box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
}

.page .content .actions {float: left;}
.page .content .actions button {
	float: left;
	width: 30px;
	height: 30px;
	background-color: var(--color1);
	border: 1px solid rgba(0,0,0,.08);
	border-radius: 20px;
	color: #fff;
	font-size: 13px;
	font-weight: 600;
	margin: 5px 0 0 -36px;
	cursor: pointer;
	transition: all .4s linear;
	text-align: center;
}

.page .content .actions input {
	float: left;
	width: 220px;
	height: 40px;
	background-color: #fff;
	border-radius: 4px;
	border: 1px solid rgba(0,0,0,.08);
	padding: 0 10px;
	font-size: 13px;
}

.categoria .cat {
	float: left;
	width: auto;
	height: 40px;
	padding: 0 16px;
	line-height: 38px;
	font-size: 13px;
	color: var(--color2);
	background-color: #fff;
	border-radius: 4px;
	border: 1px solid #ccc;
	user-select: none;
	cursor: pointer;
	margin-left: 8px;
}
.categoria .cat.active {background-color: var(--color1);color: #fff;font-weight: 600;border: 0;}

.categoria .cat .icon {
	float: left;
	width: 20px;
	height: 20px;
	margin: 10px 10px 0 0;
	background-position: center;
	background-repeat: no-repeat;
}

.content .mobs {
	position: relative;
	float: left;
	width: 100%;
	height: auto;
	margin-top: 30px;
}

.content .mobs .mob {
	position: relative;
	float: left;
	width: calc(25% - 10px);
	height: 80px;
	background-color: #fff;
	border: 1px solid #ccc;
	border-left: 0;
	border-radius: 4px;
	margin: 8px 0 0 5px;
	z-index: 0;
}

.content .mobs .mob .icon {
	position: relative;
	float: left;
	width: 80px;
	height: 100%;
	background-color: var(--color1);
	background-position: center;
	background-repeat: no-repeat;
	border-radius: 4px 0 0 4px;
}
.content .mobs .mob .tip {top: -44px;}

.content .mobs .mob .icon .status {
	float: right;
	width: 14px;
	height: 14px;
	margin: 70px -7px 0 0;
	background-repeat: no-repeat;
	background-position: center;
}
.content .mobs .mob .icon .status.manteve {background-image: url('../assets/img/icon24.png');}
.content .mobs .mob .icon .status.caiu {background-image: url('../assets/img/icon23.png');}
.content .mobs .mob .icon .status.subiu {background-image: url('../assets/img/icon25.png');}

.content .mobs .mob .name {
	float: left;
	width: calc(100% - 88px);
	height: 20px;
	line-height: 20px;
	font-size: 13px;
	font-weight: 600;
	margin: 8px 0 0 8px;
}

.content .mobs .mob .att {
	float: left;
	width: calc(100% - 88px);
	font-size: 12px;
	opacity: .7;
	margin: 8px 0 0 8px;
}
</style>