<template>
	<div class="header">
		<div class="topHeader">
			<div class="top">
				<Player :logged="logged" :locutorOfc="this.locutorOfc"/>
				<div class="boxLogin" v-if="!logged">
					<div class="button" @click="showL()">
						<label>
							<input type="radio" v-model="activeComponent" value="ModLogin">
							<div class="icon wh"><i class="fa-solid fa-right-to-bracket"></i></div>
							<span>Entrar</span>
						</label>
					</div>
					<span class="str">ou</span>
					<div class="button orange" @click="showR()">
						<label>
							<input type="radio" v-model="activeComponent" value="ModRegistro">
							<div class="icon"><i class="fa-solid fa-user-plus"></i></div>
							<span>Registre-se</span>
						</label>
					</div>
				</div>
				<div class="boxLogin" v-else>
					<div class="bAvatar" :style="{backgroundImage: 'url('+this.uploads+this.img+')'}"></div>
					<div class="nick">Olá, <span>{{this.nick}}</span></div>
					<div class="config" @click="showC()">
						<label>
							<input type="radio" v-model="activeComponent" value="ModConfig">
							<div class="icon"><i class="fa-solid fa-gears"></i></div>
							<span>Config.</span>
						</label>
					</div>
					<div class="config">
						<div class="icon" @click="logout()"><i class="fa-solid fa-arrow-right-from-bracket"></i></div>
					</div>
				</div>
			</div>
		</div>
		<div class="backHeader" :style="{backgroundImage: 'url('+this.uploads+'temas/'+this.header+')'}">
			<div class="logo" :style="{backgroundImage: 'url('+this.uploads+'temas/'+this.logo+')'}"></div>
		</div>
		<button class="buttonM" :class="{active: mobile}" @click="mobile = !mobile"><i class="fa-solid fa-bars"></i></button>
		<nav :class="{mobileNav: mobile}">
			<ul class="mainNav" :class="{navM: !mobile}">
				<li class="mainLi">
					<router-link :to="{path: '/', name: ''}">
						<div class="icon icon3"></div>
						<p>Início</p>
						<div class="iconHover icon8"></div>
					</router-link>
				</li>
				<li class="mainLi">
					<router-link :to="{path: '/feed', name: 'Feed'}">
						<div class="icon fa"><i class="fa-solid fa-chart-simple"></i></div>
						<p>Feed</p>
						<div class="iconHover icon10"></div>
					</router-link>
				</li>
				<li class="mainLi" v-for="pc in pag_cat" :key="pc.id">
					<div>
						<div class="icon" :style="{backgroundImage: 'url('+this.uploads+pc.icon+')'}"></div>
						<p>{{pc.cat}}</p>
						<div class="iconHover" :style="{backgroundImage: 'url('+this.uploads+pc.icone+')'}"></div>
					</div>
					<div class="subNav">
						<ul>
							<li v-for="(p, index) in pc.paginas" :key="index">
								<router-link :to="{path:  `/pagina/${p.url != 'pagina' ? p.url : p.id}`, name: p.url != 'pagina' ? p.url : 'pagina', params: {url: p.url, id: p.id}}">
									<span>{{p.title}}</span>
								</router-link>
							</li>
						</ul>
					</div>
				</li>
				<li class="mainLi">
					<router-link :to="{path: '/api', name: 'PageApi'}">
						<div class="icon icon9"></div>
						<p>API</p>
						<div class="iconHover icon11"></div>
					</router-link>
				</li>
			</ul>
		</nav>
		<div>
			<KeepAlive>
					<component :is="activeComponent" @close="activeComponent = ''" :key="activeComponent"
					@forgot="activeComponent = 'ModForgot'" 
					@login="activeComponent = 'ModLogin'"></component>
			</KeepAlive>
			<Transition name="slide-fade">
				<Alerta v-if="textAlert != ''" :text="this.textAlert" />
			</Transition>
			<PageCharge v-if="showPg" :logo="this.logo"/>
		</div>
	</div>
</template>

<script>
import ModLogin from './modals/modLogin.vue'
import ModRegistro from './modals/modResgistro.vue'
import ModConfig from './modals/modConfig.vue'
import axios from 'axios'
import {base, uploads} from '../../var.js'
import Player from './home/player.vue'
import Alerta from './usefulness/alert.vue'
import PageCharge from './usefulness/charge.vue'
import ModForgot from './modals/modForgot.vue'

export default {
	name: 'ItemHeader',
	components: {
		ModLogin,
		ModRegistro,
		ModConfig,
		Player,
		Alerta,
		PageCharge,
		ModForgot
	},
	emits: ['close', 'forgot', 'login'],
	props: ['logo', 'header'],
	data() {
		return {
			activeComponent: '',
			showLogin: false,
			showRegistro: false,
			showConfig: false,
			logged: false,
			nick: '',
			id: 0,
			img: '',
			textAlert: '',
			pag_cat: [],
			pag: [],
			uploads: uploads,
			mobile: false,
			showPg: true,
			locutorOfc: '',
			forgot: false
		}
	},
	methods: {
		showL(){
			this.showLogin = !this.showLogin
		},
		showR(){
			this.showRegistro = !this.showRegistro
		},
		showC(){
			this.showConfig = !this.showConfig
		},
		async Logado(){
			const req = await axios.get(base+'page/logged');
			this.logged = req.data.logged;
			this.nick = req.data.nick;
			this.id = req.data.id
			this.img = req.data.img
		},
		async logout() {
			const req = await axios.get(base+'page/logout')
			this.textAlert = req.data.text;
			setTimeout(() => {
				this.textAlert = '';
				document.location.reload(true);
			}, 2000);
		},
		async getCatPag(){
			const req = await axios.get(base+'getItens/pag_cat')
			this.pag_cat = req.data
		},
		async getPag(id){
			const req = await axios.post(base+'getItens/getpagina', {
				id: id
			})
			this.pag = req.data
		}
	},
	mounted() {
		this.Logado();
		this.getCatPag();
		setTimeout(() => {
			this.showPg = false
		}, 1500);
	}
}
</script>

<style scoped>
.header {
	position: relative;
	width: 100%;
	height: auto;
}

.header .top,
.header nav {
	position: relative;
	width: var(--width);
	height: 100%;
	display: flex;
	justify-content: space-between;
	margin: 0 auto;
}

.header .topHeader {
	width: 100%;
	height: 90px;
	border-bottom: 3px solid var(--color1);
	background-color: #0b1940;
}

.boxLogin {
	display: flex;
	justify-content: space-between;
	width: 27%;
	height: 40px;
	margin-top: 24px;
}

.boxLogin div.button {
	position: relative;
	width: 140px;
	height: 100%;
	font-size: 13px;
	font-weight: 600;
	color: #fff;
	border: 2px solid var(--color1);
	background-color: var(--color1);
	border-radius: 5px;
	cursor: pointer;
}

.boxLogin .button .icon {
	float: left;
	width: 36px;
	height: 36px;
	text-align: center;
	font-size: 12px;
	line-height: 36px;
	color: var(--color1);
	border-radius: 4px;
}
.boxLogin .button .icon.wh {color: #fff;}

.boxLogin .button span {
	float: left;
	width: calc(100% - 50px);
	text-align: center;
	line-height: 34px;
}

.boxLogin .button.orange {background-color: #fff; color: var(--color1);}
.boxLogin .button.orange .icon {  color: #fff;}
.boxLogin .button.orange .icon {width: 32px;height: 32px;margin: 2px 0 0 2px;line-height: 32px;}

.boxLogin .str {float: left; color: var(--color2);font-size: 13px;position: relative;top: 10px;font-weight: 600;opacity: .7;}

.header .backHeader {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	height: 430px;
	background-color: #0D55AF;
	background-position: center;
	background-repeat: repeat-x;
}

.header .backHeader .logo {
	width: 300px;
	height: 260px;
	position: relative;
	float: left;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	background-position: center;
	background-repeat: no-repeat;
}

.header nav {
	position: relative;
	height: 100px;
	background-color: #0b1940;
	border-radius: 4px;
	box-shadow: 0 1px 5px rgba(0,0,0,.15);
	margin-top: -50px;
}

.header .mainNav {
	width: 100%;
	display: flex;
	justify-content: space-around;
}

.header ul .mainLi {
	position: relative;
	width: 20%;
	height: 100%;
	padding: 0 30px;
	display: flex;
	justify-content: space-evenly;
	transition: all .3s ease-in;
}
.header ul > .mainLi:hover {background-color: var(--color1);}
.header ul .mainLi:first-of-type {border-radius: 4px 0 0 4px;}
.header ul .mainLi:last-of-type {border-radius: 0 4px 4px 0;}

.header ul .mainLi:hover .icon,
.header ul .mainLi:hover p {opacity: 0;}

.header ul .mainLi .iconHover {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-position: center;
	background-repeat: no-repeat;
	opacity: 0;
	visibility: hidden;
	transition: all .3s ease-in;
}
.header ul .mainLi:first-of-type .iconHover {position: relative;}

.header ul .mainLi:hover .iconHover {
	visibility: visible;
	opacity: 1;
}

.header ul .mainLi .icon {
	float: left;
	width: 30px;
	height: 25px;
	background-position: center;
	background-repeat: no-repeat;
	margin-top: 38px;
	text-align: center;
	font-size: 14px;
	color: rgba(0,0,0,.5);
	line-height: 28px;
}
.header ul .mainLi .icon.fa {transform: rotate(90deg);}

.header ul .mainLi p {
	float: left;
	width: auto;
	height: 20px;
	margin-top: 42px;
	font-size: 13px;
	font-weight: 600;
	color: var(--color2);
	margin-left: 5px;
	user-select: none;
}

.header ul .mainLi .subNav {
	position: absolute;
	width: 150px;
	height: auto;
	background-color: #fff;
	top: 100%;
	left: 38px;
	box-shadow: 0 1px 5px rgba(0,0,0,.15);
	border-radius: 6px;
	z-index: 99;
	transform: translateY(-2em);
	visibility: hidden;
	opacity: 0;
	transition: all 0.3s ease-in-out 0s; /* , visibility 0s linear 0.3s, z-index 0s linear 0.01s; */
}
.header ul .mainLi:hover .subNav { visibility: visible;opacity: 1;transform: translateY(0%);transition-delay: 0s, 0s, 0.3s;}

.header ul .mainLi .subNav::before{
	content: '';
	position: absolute;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 6.5px 8px 6.5px;
	border-color: transparent transparent #fff transparent;
	margin: -5px 0 0 50%;
	transform: translateX(-50%);
}

.header ul .mainLi .subNav ul {float: left;padding: 6px 0;width: 100%;}
.header ul .mainLi .subNav ul li {
	float: left;
	width: 100%;
	height: 38px;
	font-size: 13px;
	transition: all .3s linear;
}
.header ul .mainLi .subNav ul li:hover {background-color: var(--color1);}

.header ul .mainLi .subNav ul li span {
	float: left;
	line-height: 38px;
	width: 100%;
	height: 100%;
	padding: 0 8px;
	cursor: pointer;
}
.header ul .mainLi .subNav ul li span:hover {font-weight: 600; background-color: var(--color1);color: #fff;}

.boxLogin .bAvatar {
	float: left;
	width: 40px;
	height: 40px;
	background-position: center;
	background-size: cover;
	border-radius: 4px;
}

.boxLogin .bAvatar .avatar {
	float: left;
	width: 100%;
	height: 100%;
	background-position: center 0px;
	background-repeat: no-repeat;
}

.boxLogin .nick {
	width: auto;
	height: 20px;
	font-size: 13px;
	font-weight: 600;
	color: #fff;
	opacity: .7;
	margin-top: 14px;
}
/* .boxLogin .nick span {color: #fff;} */

.boxLogin .config .icon {
	float: left;
	width: 30px;
	height: 30px;
	color: #fff;
	font-size: 13px;
	margin-top: 7px;
	text-align: center;
	line-height: 30px;
}

.boxLogin .config {cursor: pointer;}

.boxLogin .config span {
	float: left;
	width: auto;
	height: 20px;
	line-height: 20px;
	margin: 12px 0 0 10px;
	font-size: 13px;
	font-weight: 600;
	opacity: .7;
	color: #fff;
}

</style>