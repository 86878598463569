<template>
	<div class="page">
		<ItemTags/>
		<div class="feed item">
			<div class="top">
				<div class="left">
					<div class="icon"></div>
					<div class="title">LiveNight <span>Social</span></div>
				</div>
				<div class="actions">
					<button v-if="this.logged == true" class="post" @click="showPost = true">Postar</button>
					<div>
						<input type="text" name="" placeholder="Procure usuário..." v-model="search" @change="getUser()">
						<button class="search"><i class="fa-solid fa-magnifying-glass"></i></button>
					</div>
				</div>
			</div>
			<div class="content">
				<div class="box" v-for="f in post" :key="f.id">
					<div class="topF">
						<div class="avatar" :style="{backgroundImage: 'url('+this.uploads+f.avatar+')'}"></div>
						<div class="autor">{{f.autor}}</div>
						<div class="date">{{f.time}}</div>
						<div class="repost" v-if="logged" @click="sRepost(f.id)">Responder</div>
						<div class="denunc" v-if="logged" @click="setDenun(f.id)"><i class="fa-solid fa-triangle-exclamation"></i></div>
					</div>
					<div class="contentF" v-html="f.texto"></div>
					<FeedComment :idre="f.id" :logged="logged" />
				</div>
				<button class="btnMais" v-if="btnMais == true" @click="getMais()">Mostrar mais</button>
			</div>
		</div>
	</div>
	<Transition>
		<Respost v-if="this.showRepost == true" :idre="this.idre" @close="this.showRepost = false, getFeed()"/>
	</Transition>
	<Transition>
		<Post v-if="this.showPost == true" @close="this.showPost = false, getFeed()"/>
	</Transition>
	<Transition>
		<Alerta v-if="textA != ''" :text="textA" />
	</Transition>
</template>

<script>
import ItemTags from '../components/home/hastags.vue'
import Respost from '../components/feed/postRepost.vue'
import Post from '../components/feed/postFeed.vue'
import axios from 'axios'
import {base,uploads} from '../../var.js'
import FeedComment from '../components/feed/feed-comments.vue'
import Alerta from '../components/usefulness/alert.vue'

export default {
	components: {
		ItemTags,
		Respost,
		Post,
		FeedComment,
		Alerta
	},
	name: 'PageFeed',
	emits: ['close'],
	data() {
		return {
			post: [],
			mais: 0,
			showRepost: false,
			idre: 0,
			showPost: false,
			search: '',
			logged: false,
			btnMais: false,
			uploads: uploads,
			textA: ''
		}
	},
	methods: {
		async getFeed(){
			const req = await axios.get(base+'getItens/feed')
			this.post = req.data.posts
			if(req.data.total < this.mais){
				this.btnMais = true;
			}
		},
		async getMais(){
			let maisp = this.mais + 20;
			const req = await axios.post(base+'getItens/feed', {
				limit: maisp
			})
			this.post = req.data.posts
			if(req.data.total < this.mais){
				this.btnMais = true;
			}
		},
		sRepost(idr){
			this.showRepost = !this.showRepost;
			this.idre = idr
		},
		async Logado(){
			const req = await axios.get(base+'page/logged');
			this.logged = req.data.logged;
		},
		async setDenun(id) {
			const den = confirm("Tem certeza?");
			if (den) {
				const req = await axios.post(base+'getItens/denuncia', {
					id: id,
					comm: false
				})
				this.textA = req.data.text
			}
			setTimeout(() => {
				this.textA = ''
			}, 3000);
		},
		async getUser(){
			const req = await axios.post(base+'getItens/feed', {
				se: this.search
			})
			this.post = req.data.posts
		}
	},
	mounted(){
		this.Logado();
		this.getFeed();
		document.title = 'LN Social • LiveNight';
	}
}
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.page .feed {width: 70%;float: right;margin-top: 30px;}

.page .top {
	float: left;
	width: 100%;
	height: 50px;
}

.page .top .title {
	float: left;
	width: auto;
	height: 20px;
	color: var(--color2);
	font-size: 14px;
	line-height: 20px;
	margin: 14px 0 0 14px;
}
.page .top .title span {font-weight: 600;}

.page .top .actions {
	position: relative;
	float: right;
	width: 40%;
	height: 40px;
	margin-top: 5px;
}

.page .top .actions button.post {
	float: left;
	left: 0;
	width: auto;
}

.page .top .actions button.post {
	float: left;
	width: auto;
	height: 40px;
	background-color: #fff;
	border: 1px solid rgba(0,0,0,.08);
	border-radius: 20px;
	color: rgba(0,0,0,.7);
	font-size: 13px;
	font-weight: 600;
	margin-left: 30px;
	cursor: pointer;
	padding: 0 16px;
	transition: all .4s linear;
	text-align: center;
}
.page .top .actions button.post:hover {background-color: var(--color1);color: #fff;}

.page .top .actions input {
	float: right;
	width: 220px;
	height: 40px;
	background-color: #fff;
	border-radius: 20px;
	border: 1px solid rgba(0,0,0,.08);
	padding: 0 10px;
	font-size: 13px;
}

.page .top .actions div {position: relative;}
.page .top .actions button.search:last-of-type {
	position: absolute;
	right: 6px;
	width: 30px;
	height: 28px;
	background-color: var(--color1);
	color: #fff;
	margin: 6px 0 0 -34px;
	border-radius: 30px;
	padding: 0;
}

.page .content {
	position: relative;
	float: left;
	width: 100%;
	height: auto;
	background-color: #fff;
	border-radius: 4px;
	border: 1px solid rgba(0,0,0,.08);
	box-shadow: 0 1px 6px rgba(0,0,0,.15);
	padding: 14px;
	margin-top: 20px;
}

.content .box {
	float: left;
	width: 100%;
	height: auto;
	margin-top: 10px;
}
.content .box:first-of-type {margin: 0;}

.content .box .topF .avatar {
	float: left;
	width: 50px;
	height: 50px;
	background-position: center;
	background-size: cover;
	border-radius: 4px;
}

.content .box .topF .autor,
.content .box .topF .date {
	float: left;
	width: 60%;
	height: 20px;
	margin: 4px 0 0 10px;
	font-size: 13px;
	color: #444;
	line-height: 20px;
}
.content .box .topF .date {margin: 0px 0 0 8px;}
.content .box .topF .autor {font-weight: 600;}

.content .box .topF .repost {
	float: right;
	width: auto;
	height: 30px;
	font-size: 13px;
	font-weight: 600;
	text-decoration: underline;
	margin: -20px 10px 0 0;
	cursor: pointer;
}

.content .box .topF .denunc {
	float: right;
	width: 30px;
	height: 30px;
	text-align: center;
	line-height: 30px;
	color: #C20600;
	font-size: 17px;
	margin: 4px -80px 0 0;
}

.content .box .content {
	float: left;
	width: 100%;
	height: auto;
	margin-top: 10px;
}

.content .box .contentF {
	position: relative;
	float: left;
	width: 100%;
	height: auto;
	color: #444;
	font-size: 13px;
	margin-top: 10px;
	border-bottom: 1px solid rgba(0,0,0,.08);
	padding: 10px 0;
}

.content .box .contentF :deep(img) {
	float: left;
	margin: 10px auto;
	width: auto;
	height: auto;
	max-width: 60%;
	border-radius: 4px;
	display: block;
}

.content .box:last-of-type .contentF {border:0}

.content .btnMais {
	position: relative;
	width: auto;
	height: 34px;
	font-size: 12px;
	font-weight: 600;
	color: #fff;
	background-color: var(--color1);
	border-radius: 20px;
	padding: 0 20px;
	margin: 10px 0 0 50%;
	transform: translateX(-50%);
}
</style>