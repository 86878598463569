<template>
		<div class="container">
			<ItemNews/>
		</div>

		<div class="container">
			<div class="between">
				<ItemTags/>
				<ItemSocial/>
			</div>
		</div>
		<div class="container">
			<div class="between">
				<ItemSlide/>
				<ItemHomes/>
			</div>
		</div>
		<div class="container">
			<div class="between">
				<LastMobs/>
				<LastBadges/>
				<LastValo/>
			</div>
		</div>
</template>

<script>
import ItemNews from '../components/home/news.vue'
import ItemTags from '../components/home/hastags.vue'
import ItemSocial from '../components/home/social.vue'
import ItemSlide from '../components/home/slide.vue'
import ItemHomes from '../components/home/homes.vue'
import LastMobs from '../components/home/last-mobs.vue'
import LastBadges from '../components/home/last-badges.vue'
import LastValo from '../components/home/last-visuais.vue'

export default {
	name: 'ItemInicio',
	components: {
		ItemNews,
		ItemTags,
		ItemSocial,
		ItemHomes,
		ItemSlide,
		LastMobs,
		LastBadges,
		LastValo
	},
	mounted(){
		document.title = 'LiveNight • O som que ilumina suas noites!';
	}
}
</script>

<style scoped>

.background {
	background-color: var(--color1);
	padding: 0 0 30px 0;
	margin-top: 30px;
	box-shadow: 0 5px 6px rgba(0,0,0,.15);
}

</style>