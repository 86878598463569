<template>
	<div class="modal">
		<div class="box">
			<div class="top">
				<div class="title"><strong>Postar</strong></div>
				<div class="close" @click="$emit('close')">X</div>
			</div>
			<div class="content">
				<form @submit="setPost">
					<textarea name="post" id="bbcodetext" placeholder="O que aconteceu hoje..." v-model="text"></textarea>
					<div class="bbcode" @click="addBBCode">
						<div v-for="(b, index) in bbcode" :key="index" :style="b[3]" :data-bbcodei="b[1]" :data-bbcodee="b[2]">
							{{b[0]}}
						</div>
					</div>
					<button>Postar</button>
				</form>
			</div>
		</div>
		<Transition name="fade">
		<alerta v-if="textA != ''" :text="this.textA"></alerta>
		</Transition>
	</div>
</template>

<script>
import axios from 'axios'
import {base} from '../../../var.js'
import Alerta from '../usefulness/alert.vue'

export default {
	name: 'Post',
	components: {
		Alerta
	},
	data() {
		return {
			text: '',
			textA: '',
			bbcode: []
		}
	},
	methods: {
		async getBB() {
			const req = await axios.post(base+'getItens/bbcode-buttons', {id: 2});
			this.bbcode = req.data
		},
		async setPost(e){
			e.preventDefault();
			const req = await axios.post(base+'page/postFeed', {
				post: this.text
			})

			this.textA = req.data.text
			setTimeout(() => {
				this.textA = ''
			}, 3000);
			if(req.data.post == true){
				this.$emit('close');
			}
		},
		addBBCode(event) {
			let targ = event.target || event.srcElement;

			const b = targ.dataset.bbcodei;
			const c = targ.dataset.bbcodee;

			const element = document.getElementById("bbcodetext");
			const eLength = element.value.length;
			const eStart = element.selectionStart;
			const eEnd = element.selectionEnd;
			const eString = element.value.substring(eStart, eEnd);
			const eOp = b + eString + c;

			element.value = element.value.substring(0, eStart) + eOp + element.value.substring(eEnd, eLength)
		}
	},
	mounted (){
		this.getBB();
	}
}
</script>

<style scoped>

.bbcode {
	position: relative;
	float: left;
	width: 80%;
}

.bbcode div {
	float: left;
	width: auto;
	height: 26px;
	border-radius: 4px;
	font-size: 12px;
	font-weight: 600;
	line-height: 26px;
	color: var(--color2);
	background-color: #fff;
	box-shadow: 0 1px 6px rgba(0,0,0,.15);
	margin: 6px 6px 0 0;
	padding: 0 8px;
	cursor: pointer;
}

.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 999;
}

.modal .box {
	position: absolute;
	width: 550px;
	height: auto;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.modal .top {
	float: left;
	width: 100%;
	height: 50px;
	background-color: var(--color1);
	border-radius: 4px 4px 0 0;
	color: #fff;
	font-size: 13px;
	line-height: 50px;
	padding: 0 20px;
}

.modal .top .close {
	position: absolute;
	top: -15px;
	right: -10px;
	width: 30px;
	height: 30px;
	background-color: #fff;
	border-radius: 30px;
	border: 1px solid var(--color1);
	color: var(--color1);
	text-align: center;
	line-height: 30px;
	font-size: 12px;
	font-weight: 800;
	user-select: none;
}

.modal .content {
	float: left;
	width: 100%;
	height: auto;
	background-color: #fff;
	border-radius: 0 0 4px 4px;
	box-shadow: 0 0 8px rgba(0,0,0,.1);
	padding: 10px;
	font-size: 13px;
}

.modal .content textarea {
	width: 100%;
	height: 100px;
	max-width: 100%;
	min-width: 100%;
	border: 1px solid rgba(0,0,0,.08);
	border-radius: 4px;
	padding: 8px;
}

.modal .content button {
	float: right;
	width: auto;
	height: 40px;
	padding: 0 20px;
	font-size: 13px;
	font-weight: 600;
	color: #444;
	background-color: var(--color1);
	border-radius: 20px;
	margin-top: 8px;
	transition: all .3s ease;
	border: 1px solid;
	cursor: pointer;
}
.modal .content button:hover {background-color: #fff;color: var(--color2);border: 1px solid var(--color1);}
</style>