<template>
    <div class="modal">
		<div class="box">
			<div class="top">
				<div class="title"><strong>Configurações</strong></div>
				<div class="close" @click="$emit('close')">X</div>
			</div>
            <div class="content">
				<div class="setImg setConf">
					<span>Imagem</span>
					<div class="imagem" :style="{backgroundImage: 'url('+this.uploads+this.imagem+')'}"></div>
					<form @submit="submitFile" enctype="multipart/form-data">
						<input type="file" name="alterar" accept="image/*" id="file" ref="file" @change="uploadfile()">
						<button>MUDAR</button>
					</form>
				</div>
				<div class="twoInp setConf">
					<span>Alterar senha</span>
					<form @submit="setPass">
						<input type="password" name="pass" placeholder="Digite sua senha" v-model="pass">
						<input type="password" name="passRepeat" placeholder="Confirme sua senha" v-model="passR">
						<button>MUDAR</button>
					</form>
				</div>
			</div>
        </div>
		<Transition name="slide-fade">
		<alerta v-if="textA != ''" :text="this.textA"></alerta>
		</Transition>
    </div>
</template>

<script>
import axios from 'axios'
import {base, uploads} from '../../../var.js'
import Alerta from '../usefulness/alert.vue'

export default {
    name: 'ModConfig',
	components: {
		Alerta
	},
    data(){
        return {
			imagem: '',
			file: '',
			pass: '',
			passR: '',
			textA: '',
			uploads: uploads
		}
    },
	methods: {
		async setPass(e) {
			e.preventDefault();
			const req = axios.post(base+'page/changePass', {
				pass: this.pass,
				passR: this.passR
			});
			this.textA = req.data.text
			setTimeout(() => {
                this.textA = ''
            }, 3000);
		},
		async getImg() {
			const req = await axios.get(base+'page/logged');
			this.imagem = req.data.img
		},
		async submitFile(e) {
			e.preventDefault();
			let formData = new FormData();
			formData.append('file', this.file);

			await axios.post(base+'page/profileImg',
				formData, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}
			).then(function (a) {
				this.imagem = a.data.img
				this.textA = a.data.text
				setTimeout(() => {
					this.textA = ''
				}, 3000);
			})
		},
		uploadfile() {
			this.file = this.$refs.file.files[0];
		}
	},
	mounted(){
		this.getImg();
	}
}
</script>

<style scoped>
.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 999;
	background-color: rgba(0,0,0,.3);
	transition: enter .3s linear;
}

.modal .box {
	position: absolute;
	width: 550px;
	height: auto;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.modal .top {
	float: left;
	width: 100%;
	height: 50px;
	background-color: var(--color1);
	border-radius: 4px 4px 0 0;
	color: #fff;
	font-size: 13px;
	line-height: 50px;
	padding: 0 20px;
}

.modal .top .close {
	position: absolute;
	top: -15px;
	right: -10px;
	width: 30px;
	height: 30px;
	background-color: #fff;
	border-radius: 30px;
	border: 1px solid var(--color1);
	color: var(--color1);
	text-align: center;
	line-height: 30px;
	font-size: 12px;
	font-weight: 800;
	user-select: none;
}

.modal .content {
	float: left;
	width: 100%;
	height: auto;
	background-color: #fff;
	border-radius: 0 0 4px 4px;
	box-shadow: 0 0 8px rgba(0,0,0,.1);
	padding: 10px;
	font-size: 13px;
}

.modal .box .content .setConf {
	float: left;
	width: 100%;
	height: auto;
	margin-top: 10px;
}

.modal .box .content .setConf span {
	float: left;
	width: 100%;
	height: 20px;
	font-size: 13px;
	font-weight: 600;
}

.modal .box .content .setConf input {
	float: left;
}

.modal .box .content .setConf button {
	float: right;
	width: auto;
	height: 50px;
	font-size: 13px;
	font-weight: 600;
	color: #fff;
	background-color: var(--color1);
	border-radius: 4px;
	margin-top: 6px;
	padding: 0 20px;
	cursor: pointer;
}

.modal .box .content .setImg .imagem {
	float: left;
	width: 100px;
	height: 100px;
	border-radius: 4px;
	margin: 6px 0 0 0;
	background-color: #ccc;
	background-size: cover;
}

.modal .box .content .setImg input {
	width: calc(100% - 108px);
	height: 46px;
	margin: 6px 0 0 8px;
	border: 1px solid rgba(0,0,0,.08);
	background-color: #fff;
	border-radius: 6px;
	padding: 12px;
	font-size: 12px;
}

.modal .box .content .twoInp input {
	float: left;
	width: calc(50% - 6px);
	height: 46px;
	margin: 6px 3px 0 3px;
	border: 1px solid rgba(0,0,0,.08);
	padding: 0 14px;
	font-size: 12px;
	border-radius: 6px;
}
</style>