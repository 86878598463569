<template>
	<div class="tags item">
		<div class="top">
			<div class="left">
				<div class="icon"></div>
				<div class="title">Últimas <span>Hastags</span></div>
			</div>
		</div>
		<div class="content" v-if="this.tags.length > 0">
			<div class="row" v-for="h in tags" :key="h.id" :class="h.rankStr">
				<div class="rank">{{h.rank}}º</div>
				<span v-html="h.hastag"></span>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios'
import {base} from '../../../var.js'

export default {
	name: 'ItemTags',
	data() {
		return {
			tags: []
		}
	},
	methods: {
		async getTags(){
			const req = await axios.get(base+'getItens/hastags')
			this.tags = req.data
		}
	},
	mounted() {
		this.getTags();
	}
}
</script>

<style scoped>
.tags {
	width: 30%;
}

.tags .content {
	width: calc(100% - 30px);
	height: auto;
	padding: 4px 10px 8px 10px;
	background-color: #fff;
	border: 1px solid rgba(0, 0, 0, 0.08);
	border-radius: 4px;
}

.tags .row {
	float: left;
	width: 100%;
	height: auto;
	margin-top: 8px;
}

.tags .row .rank {
	float: left;
	position: relative;
	width: 50px;
	height: 50px;
	text-align: center;
	font-size: 14px;
	line-height: 50px;
	padding-left: 4px;
	color: #fff;
	font-weight: 600;
	border-radius: 4px;
	background-color: var(--color1);
	user-select: none;
}

.tags .row.gold .rank {background-color: #EEB63F;}
.tags .row.silver .rank {background-color: #C0C0C0;}
.tags .row.bronze .rank {background-color: #D29755;}

.tags .row span {
	float: left;
	width: calc(100% - 60px);
	font-size: 13px;
	color: var(--color2);
	font-weight: 600;
	margin: 16px 0 0 10px;
}

</style>