<template>
    <div class="news item">
        <div class="top">
            <div class="left">
                <div class="icon"></div>
                <div class="title">Últimas <span>Notícias</span></div>
            </div>
            <div class="actions">
                <button :disabled='disableP == true' @click="getNews(this.prev)"><i class="fa-solid fa-angle-left"></i></button>
                <button :disabled='disableN == true' @click="getNews(this.next)"><i class="fa-solid fa-angle-right"></i></button>
                <input type="text" name="search" placeholder="Procure pro notícias" v-model="search" @change="getNews()">
                <button class="search"><i class="fa-solid fa-magnifying-glass"></i></button>
                <div class="select">
                    <div class="selected">{{this.selectedCat}}</div>
                    <div class="arrow"><i class="fa-solid fa-angle-down"></i></div>
                    <div class="boxOptions">
						<div class="option" @click="getNews(1), this.selectedCat = 'Selecione'">Todas</div>
                        <div class="option" v-for="c in category" :key="c.id" @click="getNews(1, c.id), this.selectedCat = c.categoria">{{c.categoria}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="content">
			<div class="boxFixeds">
				<div class="box boxf" v-for="n in newsF" :key="n.id">
					<div class="img" :style="{backgroundImage: 'url('+this.uploads+'noticias/'+n.imagem+')'}"><div class="live"><i class="fa-solid fa-circle"></i> DESTAQUE</div></div>
					<router-link :to="{path: `news/${n.id}/${n.url}`, name: 'News', params: {id: n.id, url: n.url}}">
						<div class="title nobr">{{n.titulo}}</div>
					</router-link>
					<div class="descricao nobr">{{n.descricao}}</div>
					<div class="leftBox">
						<div class="data">
							<i class="fa-solid fa-message"></i>
							<span>COMENTÁRIOS</span>
							<span>{{n.comments}}</span>
						</div>
						<div class="data">
							<i class="fa-solid fa-user"></i>
							<span>AUTOR</span>
							<div class="avatar" :style="{backgroundImage: 'url('+this.uploads+n.avatar+')'}"></div>
							<span>{{n.autor}}</span>
						</div>
					</div>
					<div class="rightBox">
						<div class="data">
							<i class="fa-solid fa-calendar"></i>
							<span>POSTADO EM</span>
							<span>{{n.time}}, {{n.date}}</span>
						</div>
						<div class="category tip">
							<MyTip :title="n.category"/>
							<div class="icon" :style="{backgroundImage: 'url('+this.uploads+n.icon_cat+')'}"></div>
							<span class="nobr">{{n.category}}</span>
						</div>
					</div>
				</div>
			</div>
			<div class="boxNormal">
				<div class="box boxn animate__animated animate__bounceIn" v-for="n in news" :key="n.id">
					<div class="img" :style="{backgroundImage: 'url('+this.uploads+'noticias/'+n.imagem+')'}"></div>
					<router-link :to="{path: `news/${n.id}/${n.url}`, name: 'News', params: {id: n.id, url: n.url}}">
						<div class="title nobr">{{n.titulo}}</div>
					</router-link>
					<div class="descricao nobr">{{n.descricao}}</div>
					<div class="leftBox">
						<div class="data">
							<i class="fa-solid fa-message"></i>
							<span>COMENT...</span>
							<span>{{n.comments}}</span>
						</div>
						<div class="data">
							<i class="fa-solid fa-user"></i>
							<span>AUTOR</span>
							<div class="avatar" :style="{backgroundImage: 'url('+this.uploads+n.avatar+')'}"></div>
							<span>{{n.autor}}</span>
						</div>
					</div>
					<div class="rightBox">
						<div class="data">
							<i class="fa-solid fa-calendar"></i>
							<span>POSTADO</span>
							<span>{{n.time}}, {{n.date}}</span>
						</div>
						<div class="category tip">
							<MyTip :title="n.category"/>
							<div class="icon" :style="{backgroundImage: 'url('+this.uploads+n.icon_cat+')'}"></div>
							<span class="nobr">{{n.category}}</span>
						</div>
					</div>
				</div>
			</div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import MyTip from '../usefulness/tooltip.vue'
import {base, uploads} from '../../../var.js'

export default {
    name: 'ItemNews',
	components: {
		MyTip
	},
    data() {
        return {
			newsF: [],
			news: [],
			search: '',
			pag: 1,
			prev: null,
			next: null,
			disableP: false,
			disableN: false,
			category: [],
			selCat: 0,
			uploads: uploads,
			selectedCat: 'Selecione'
        }
    },
	methods: {
		async getNewsF() {
			const req = await axios.post(base+"getItens/newsF");
			this.newsF = req.data;
		},
		async getNews(paga=1, cate=0) {
			const req = await axios.post(base+'getItens/news', {
				pag: paga,
				s: this.search,
				cat: cate
			});
			this.news = req.data.news;
			this.prev = req.data.prev;
			this.next = req.data.next;
			
			if(req.data.prev == 0){this.disableP = true;}else {this.disableP = false;}
			if(req.data.next == req.data.total){this.disableN = true;}else {this.disableN = false;}
		},
		async getCat(){
			const req = await fetch(base+`getItens/categoria`);
			const data = await req.json();
			this.category = data;
		}
	},
	mounted() {
		this.getNewsF();
		this.getNews();
		this.getCat();
	}
}

</script>

<style scoped>

.news .top .actions button {
	float: left;
	width: 50px;
	height: 40px;
	background-color: #0f1320;
	border: 1px solid rgba(0,0,0,.08);
	border-radius: 4px;
	color: rgba(0,0,0,.5);
	font-size: 13px;
	margin-right: 6px;
	cursor: pointer;
	transition: all .4s linear;
}
.news .top .actions button:hover {background-color: var(--color1);color: #fff;}

.news .top .actions input {
	float: left;
	width: 220px;
	height: 40px;
	background-color: #0b1940;
	border-radius: 4px 0 0 4px;
	border: 1px solid rgba(0,0,0,.08);
	padding: 0 10px;
	font-size: 13px;
}

.news .top .actions button:last-of-type {
	width: 30px;
	height: 28px;
	background-color: var(--color1);
	color: #0b1940;
	margin: 6px 0 0 -34px;
	border-radius: 30px;
}

.news .top .actions .select {
	position: relative;
	float: left;
	width: 180px;
	height: 40px;
	border-radius: 0 4px 4px 0;
	border: 1px solid rgba(0,0,0,.08);
	background-color: #0b1940;
}

.news .top .actions .select .selected {
	float: left;
	width: calc(100% - 22px);
	height: 40px;
	line-height: 40px;
	user-select: none;
	color: var(--color2);
	font-size: 13px;
	padding: 0 10px;
}

.news .top .actions .select .arrow {
	float: right;
	font-size: 13px;
	color: var(--color2);
	margin: 12px 10px 0 0;
}

.news .top .actions .select .boxOptions {
	position: absolute;
	top: 100%;
	left: 0;
	width: 180px;
	height: 200px;
	background-color: #fff;
	border-radius: 4px;
	padding: 6px 0;
	text-align: center;
	box-shadow: 0 1px 5px rgba(0,0,0,.1);
	font-size: 12px;
	visibility: hidden;
	opacity: 0;
	transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
	transform: translateY(-2em);
	overflow: scroll;
	overflow-x: none;
	z-index: 9;
}
.news .top .actions .select:hover .boxOptions,
.news .top .actions .select:focus .boxOptions,
.news .top .actions .select:focus-within .boxOptions {visibility: visible;opacity: 1;transform: translateY(0%);transition-delay: 0s, 0s, 0.3s;}

.news .top .actions .select .boxOptions::-webkit-scrollbar{width: 4px;}
.news .top .actions .select .boxOptions::-webkit-scrollbar-button { display: none; }
.news .top .actions .select .boxOptions::-webkit-scrollbar-track-piece { background-color: rgba(0,0,0,0.0); }
.news .top .actions .select .boxOptions::-webkit-scrollbar-thumb { background-color: rgba(0,0,0,0.3); width: 4px; border-radius: 4px;}

.news .top .actions .select .boxOptions .option {
	float: left;
	width: 100%;
	height: 30px;
	line-height: 30px;
	transition: all .3s linear;
	cursor: pointer;
}
.news .top .actions .select .boxOptions .option:hover {font-weight: 600;background-color: var(--color1);color: #fff;}

.news .content {
	position: relative;
	width: 100%;
	height: auto;
}

.news .boxFixeds,
.news .boxNormal {
	width: 100%;
	display: flex;
	justify-content: space-evenly;
}

.news .boxNormal {
	margin-top: 30px;
}

.news .box {
	width: calc(25% - 5px);
	height: 308px;
	background-color: #0b1940;
	border-radius: 4px;
	border: 1px solid rgba(0,0,0,.08);
	padding: 5px;

}
.news .boxf {width: calc(33% - 10px);}

.news .box .img {
	position: relative;
	width: 100%;
	height: 136px;
	border-radius: 5px;
	background-position: center;
	background-size: cover;
}

.news .boxf .img .live {
	position: absolute;
	bottom: 10px;
	right: 10px;
	width: auto;
	height: 26px;
	border-radius: 6px;
	background-color: #F53B2D;
	color: #fff;
	line-height: 26px;
	font-size: 12px;
	font-weight: 600;
	padding: 0 10px;
}
.news .boxf .img .live i {animation: 2s liveFix infinite ease;}

@keyframes liveFix {
	0%{
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

.news .box .title {
	width: 100%;
	height: 20px;
	font-size: 14px;
	font-weight: 600;
	color: var(--color2);
	margin-top: 6px;
}

.news .box .descricao {
	width: 100%;
	height: 20px;
	font-size: 12px;
	font-weight: 600;
	color: #fff;
}

.news .box .leftBox {
	float: left;
	width: 50%;
	height: auto;
}

.news .box .rightBox {
	float: left;
	width: 50%;
	height: auto;
}

.news .box .data {
	position: relative;
	float: left;
	width: 90%;
	height: 48px;
	margin: 8px 0 0 10%;
}
.news .boxn .data {width: 100%;margin-left: 0;}

.news .box .leftBox .data {
	border-right: 1px solid rgba(0,0,0,.1);
}

.news .box .data i {
	position: absolute;
	top: 2px;
	left: 2px;
	width: 20px;
	height: 20px;
	text-align: center;
	line-height: 20px;
	font-size: 12px;
	color: var(--color2);
	opacity: .5;
}

.news .box .data span {
	float: left;
	width: 100%;
	margin: 4px 0 0 5px;
	font-size: 14px;
	color: var(--color2);
	text-align: center;
	font-weight: 600;
	opacity: .5;
}

.news .box .data span:last-of-type {
	width: 100%;
	text-align: center;
	opacity: 1;
}
.news .boxn .data span:last-of-type {padding-left: 14px;}

.news .box .data .avatar {
	position: absolute;
	top: 22px;
	left: -2px;
	width: 30px;
	height: 30px;
	background-position: center;
	background-size: cover;
	border-radius: 20px;
}

.news .box .category {
	position: relative;
	float: left;
	width: calc(100% - 20px);
	height: 40px;
	background-color: #45C600;
	border-radius: 4px;
	color: #fff;
	font-size: 13px;
	font-weight: 700;
	text-align: center;
	line-height: 38px;
	margin: 12px 0 0 12px;
	padding-left: 30px;
	text-transform: uppercase;
	user-select: none;
}

.news .box .category span {
	width: 100%;
	float: left;
}

.news .box .category .icon {
	position: absolute;
	top: 8px;
	left: 16px;
	width: 20px;
	height: 20px;
	background-position: center;
	background-repeat: no-repeat;
}
.news .boxn .category .icon {left: 6px;}

</style>