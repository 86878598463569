<template>
    <div class="error">
        Error!
    </div>
</template>

<script>
export default {
    name: 'Error',
    mounted(){
        document.title = 'Error • HabbletCreate';
    }
}
</script>

<style scoped>

</style>