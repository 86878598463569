<template>
	<div class="slide item">
		<div class="top">
			<div class="left">
				<div class="icon"></div>
				<div class="title">Sites <span>Parceiros</span></div>
			</div>
		</div>
		<div class="content">
			<transition-group name="list" tag="ul">
				<div v-for="(s, index) in slides" :key="index" v-show="currentIndex == index" class="boxSlide">
					<div class="img" :style="{backgroundImage: 'url('+this.uploads+s.imagem+')'}"></div>
					<div class="dataSlide" v-if="s.guia == 'true'">
						<a :href="s.url" target="_blank" rel="noopener noreferrer">
							<span>{{ s.title }}</span>&nbsp;&nbsp;&nbsp;
							<span>{{ s.descricao }}</span>
						</a>
					</div>
					<div class="dataSlide" v-else>
						<span>{{ s.title }}</span>&nbsp;&nbsp;&nbsp;
						<span>{{ s.descricao }}</span>
					</div>
				</div>
			</transition-group>
		</div>
	</div>
</template>

<script>
import axios from 'axios'
import {base, uploads} from '../../../var.js'

export default {
	name: 'ItemSlide',
	data() {
		return {
			slides: [],
			timer: null,
			currentIndex: 0,
			uploads: uploads
		}
	},
	mounted: function() {
		this.startSlide();
	},
	methods: {
		async getSlides(){
			const req = await axios.get(base+'getItens/slide')
			this.slides = req.data
		},
		startSlide(){
			this.getSlides();
			// this.next();
			setInterval(() => {
				this.next();
			}, 5000);
		},
		next() {
			if(this.currentIndex == (this.slides.length - 1)){
				this.currentIndex = 0
			}else{
				this.currentIndex += 1;
			}
		},
		prev() {
			this.currentIndex -= 1;
		}
  },
}
</script>

<style scoped>

.list-enter-active,
.list-leave-active {
	position: absolute;
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
	position: absolute;
  opacity: 0;
  transform: translateX(30px);
}

.slide {
	width: calc(50% - 10px);
}

.slide .content {
	position: relative;
	width: 100%;
	height: 180px;
	background-color: #ccc;
	border-radius: 4px;
}

.slide .content ul,
.slide .content .boxSlide {
	width: 100%;
	height: 100%;
}

.slide .content .boxSlide .img {
	width: 100%;
	height: 100%;
	background-position: center;
	background-size: cover;
	border-radius: 4px;
}

.slide .content .boxSlide .dataSlide {
	float: right;
	width: auto;
	height: 40px;
	background-color: #fff;
	border-radius: 4px;
	line-height: 40px;
	font-size: 14px;
	font-weight: 600;
	color: #444;
	padding: 0 10px;
	margin: -46px 6px 0 0;
}

.slide .content .boxSlide .dataSlide span:last-of-type {opacity: 0.7;}

</style>