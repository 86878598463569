<template>
	<div class="player">
		<div class="boxAvatar" :style="{backgroundImage: 'url('+this.profileLoc+')'}"></div>
		<div class="boxLoc">
			<div class="line">
				<i class="fa-solid fa-microphone"></i>
				<div class="locutor">{{this.locutor}}</div>
			</div>
			<div class="line">
				<i class="fa-solid fa-music"></i>
				<div class="program">{{this.programa}}</div>
			</div>
		</div>
		<div class="play" @click="Play(!this.playing)"><i class="fa-solid fa-pause"></i></div>
		<div class="volume">
			<input type="range" name="volume" id="vol" max="100" min="0" step="1" v-model="volume" @change="rangeVol($event)">
		</div>
		<div class="boxLoc ouvm">
			<div class="line">
				<i class="fa-solid fa-headphones"></i>
				<div class="ouvintes">{{this.ouvintes}} ouvintes</div>
			</div>
			<i class="fa-solid fa-envelope" v-if="logged" @click="this.showP = !showP"></i>
			<i class="fa-solid fa-star" v-if="logged" @click="showPr = !showPr"></i>
		</div>
	<Transition name="slide-fade">
		<ModPedidos v-if="showP == true && this.locutorOfc != this.locutor" @close="this.showP = !this.showP"/>
	</Transition>
	<Transition name="slide-fade">
		<ModPresenca v-if="showPr == true && this.locutorOfc != this.locutor" @close="this.showPr = !this.showPr"/>
	</Transition>
	<Transition name="slide-fade">
		<Alerta v-if="textA != ''" :text="textA"/>
	</Transition>
	</div>
</template>

<script>
import axios from 'axios'
import {base, uploads} from '../../../var.js'
import ModPedidos from '../modals/modPedidos.vue'
import ModPresenca from '../modals/modPresenca.vue'
import Alerta from '../usefulness/alert.vue'

export default {
	name: 'Player',
	props: ['logged', 'locutorOfc'],
	emits: ['close'],
	components: {
		ModPedidos,
		ModPresenca,
		Alerta
	},
	data() {
		return {
			profileLoc: '',
			locutor: '',
			programa: '',
			ouvintes: 0,
			volume: 60,
			playing: true,
			box: '',
			showP: false,
			showPr: false,
			uploads: uploads,
			textA: '',
			audio: null
		}
	},
	methods: {
		async status(){
			const req = await axios.get(base+"page/status");
			
			this.locutor = req.data.locutor
			this.programa = req.data.programa
			this.ouvintes = req.data.ouvintes
			this.profileLoc = req.data.profile == '' ? 'https://habbletcreate.com.br/img/playerAuto.png' : this.uploads+req.data.profile;
		},
		rangeVol(e){
			const ele = e.target;

			let value = ele.value;
			ele.style.background = 'linear-gradient(to right, var(--color1) '+value+'%, var(--color1) ' + value + '%, #ccc 0%, #ccc 100%)'
			ele.setAttribute('value', this.volume)
			
			this.Volume(this.volume/100)
		},
		Play(p){
			const icon = document.querySelector('.play .fa-solid');
			this.playing = !this.playing;
			if(p == true){
				icon.classList.remove("fa-play");
				icon.classList.add("fa-pause");
				this.Volume(0.6)
				this.audio.play()
			}else{
				icon.classList.remove("fa-pause");
				icon.classList.add("fa-play");
				this.Volume(0)
			}
		},
		Volume(v){
			this.audio.volume = v;
		},
		async player(){
			const req = await axios.get(base+"page/status");
			this.audio = new Audio('https://'+req.data.ip+'/'+req.data.port+'/stream')
			this.audio.addEventListener("canplaythrough", () => {
				this.audio.play().catch(() => {
					window.addEventListener('click', () => {
						this.audio.play()
					}, { once: true })
				})
			});
			// const app = document.getElementById('app');
			// this.box = `<audio id="audio" autoplay="" controls src="https://${req.data.ip}/${req.data.port}/stream" type="audio/*" style="display: none;"></audio>`;
			// app.insertAdjacentHTML("beforeend", this.box)
			// document.getElementById("audio").play()
			setTimeout(() => {
				// document.getElementById("audio").play
			}, 2000);
		},
		async likeloc(){
			const req = await axios.get(base+'getItens/likeLoc')
			this.textA = req.data.text
			
			setTimeout(() => {
				this.textA = ''
			}, 3000);
		}
	},
	mounted(){
		this.status();
		this.player();
		setTimeout(() => {
			this.status();
		}, 10000);
	}
}
</script>

<style scoped>

.player {
	width: 60%;
	position: relative;
	display: flex;
	justify-content: space-between;
}

.player .boxAvatar {
	position: relative;
	width: 70px;
	height: 70px;
	background-color: var(--color1);
	background-position: center;
	background-size: cover;
	margin: 10px;
	z-index: 0;
	border-radius: 6px;
}

.player .boxAvatar .avatarHead {
	position: absolute;
	float: left;
	width: 64px;
	height: 110px;
	background-position: center;
	background-repeat: no-repeat;
	margin: 2px 0 0 4px;
	z-index: 3;
}

.player .boxAvatar .avatarBody {
	position: absolute;
	width: 36px;
	height: 56px;
	background-position: center;
	background-repeat: no-repeat;
	margin: 26px 0 0 18px;
	z-index: 2;
}

.player .boxAvatar .base {
	position: absolute;
	width: 100%;
	height: 34px;
	z-index: 1;
	background-image: url('../../assets/img/basePlayer.png');
	background-repeat: no-repeat;
	margin: 54px 0 0 0;
}

.player .play {
	position: relative;
    width: 30px;
    height: 30px;
    border: solid 2px var(--color2);
    border-radius: 30px;
    position: relative;
    color: var(--color2);
    font-size: 11px;
    text-align: center;
    line-height: 26px;
    margin: 30px 0 0 18px;
}

.player .boxLoc {
	position: relative;
	width: 280px;
	height: 20px;
	line-height: 20px;
	color: #fff;
	font-size: 13px;
	white-space: nowrap;
	margin-top: 14px;
}

.player .boxLoc .line i,
.player .boxLoc.ouvm {
	float: left;
	width: 20px;
	font-size: 13px;
	color: var(--color2);
	line-height: 20px;
	text-align: center;
	margin: 2px 0 20px 10px;
}

.player .boxLoc .line {
	float: left;
	width: calc(100% - 40px);
	height: 20px;
	color: var(--color2);
	font-size: 13px;
	font-weight: 600;
	line-height: 20px;
	margin: 8px 0 0 0;
	opacity: .7;
}

.player .boxLoc.ouvm {
	width: 30%;
	margin-top: 26px;
}

.player .boxLoc.ouvm > i {margin: 12px 0 0 20px;}

.player input {
	float: left;
	width: 130px;
	height: 7px;
	margin: 42px 10px 0 10px;
	-webkit-appearance: none;
	background-color: #fff;
	border-radius: 4px;
	background: linear-gradient(to right, var(--color1) 60%, var(--color1) 60%, #ccc 0%, #ccc 100%);
}

.player input::-webkit-slider-thumb {
	-webkit-appearance: none;
	width: 14px;
	height: 14px;
	border-radius: 50%;
	background: #fff;
	cursor: pointer;
	box-shadow: 0 0 6px rgba(0,0,0,.5);
	margin-top: -2px;
}
</style>