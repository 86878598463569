<template>
    <div class="page">
        <div class="top">
            <div class="title">{{this.title}}</div>
        </div>
        <div class="content" v-html="text"></div>
    </div>
</template>

<script>
import axios from 'axios'
import {base} from '../../var.js'

export default {
    name: 'PagePagina',
    data() {
        return {
            id: this.$route.params.id,
            url: this.$route.params.url,
            title: '',
            text: ''
        }
    },
    methods: {
        async getPag(){
            const req = await axios.post(base+'getItens/pagina', {
                id: this.id
            });
            this.title = req.data.title
            this.text = req.data.text
            document.title = this.title+' • HabbletCreate';
        }
    },
    mounted(){
        this.getPag();
    }
}
</script>

<style scoped>
.page {
    margin-top: 30px;
}

.page .top {
    width: 100%;
    height: 50px;
    line-height: 50px;
    color: #fff;
    border-radius: 4px 4px 0 0;
    padding-left: 20px;
    font-size: 16px;
    font-weight: 600;
}

.page .content {
    width: 100%;
    height: auto;
    background-color: #fff;
    border-radius: 8px;
    padding: 10px;
    font-size: 13px;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
}
</style>