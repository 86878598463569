<template>
	<div class="modal">
		<div class="box">
			<div class="top">
				<div class="title"><strong>Pedidos</strong></div>
				<div class="close" @click="$emit('close')">X</div>
			</div>
			<div class="content">
				<form @submit="setPed">
					<textarea name="pedido" placeholder="Digite seu pedido..." v-model="text" minlength="10"></textarea>
					<button>Enviar</button>
				</form>
			</div>
		</div>
	</div>
	<Transition>
		<Alerta v-if="alert != ''" :text="this.alert"/>
	</Transition>
</template>

<script>
import {base} from '../../../var.js'
import axios from 'axios'
import Alerta from '../usefulness/alert.vue'

export default {
	name: 'ModPedidos',
	components: {
		Alerta
	},
	data(){
		return {
			text: '',
			alert: ''
		}
	},
	methods: {
		async setPed(e) {
			e.preventDefault();
			const req = await axios.post(base+'page/requests', {
				text: this.text
			})
			this.alert = req.data.text

			setTimeout(() => {
				this.alert = ''
			}, 3000);
		}
	}
}
</script>

<style scoped>
.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 999;
	background-color: rgba(0,0,0,.3);
	transition: enter .3s linear;
}

.modal .box {
	position: absolute;
	width: 550px;
	height: auto;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.modal .top {
	float: left;
	width: 100%;
	height: 50px;
	background-color: var(--color1);
	border-radius: 4px 4px 0 0;
	color: #fff;
	font-size: 13px;
	line-height: 50px;
	padding: 0 20px;
}

.modal .top .close {
	position: absolute;
	top: -15px;
	right: -10px;
	width: 30px;
	height: 30px;
	background-color: #fff;
	border-radius: 30px;
	border: 1px solid var(--color1);
	color: var(--color1);
	text-align: center;
	line-height: 30px;
	font-size: 12px;
	font-weight: 800;
	user-select: none;
}

.modal .content {
	float: left;
	width: 100%;
	height: auto;
	background-color: #fff;
	border-radius: 0 0 4px 4px;
	box-shadow: 0 0 8px rgba(0,0,0,.1);
	padding: 10px;
	font-size: 13px;
}

.modal .content textarea {
	width: 100%;
	height: 80px;
	border: 1px solid rgba(0,0,0,.1);
	border-radius: 4px;
	padding: 10px;
	max-width: 100%;
	min-width: 100%;
	font-size: 14px;
}

.modal .content button {
	float: left;
	width: 100%;
	height: 50px;
	color: #fff;
	background-color: var(--color1);
	border-radius: 4px;
	font-size: 13px;
	font-weight: 600;
	margin-top: 16px;
}
</style>