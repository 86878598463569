<template>
	<div class="commentsfeed">
		<div class="comment" v-for="r in reposts" :key="r.id">
			<div class="topr">
				<div class="avatar" :style="{backgroundImage: 'url('+this.uploads+r.avatar+')'}"></div>
				<div class="autor">{{r.autor}}</div>
				<div class="date">{{r.time}}</div>
				<div class="denunc" v-if="logged" @click="setDenun(r.id)"><i class="fa-solid fa-triangle-exclamation"></i></div>
			</div>
			<div class="contentr" v-html="r.texto"></div>
		</div>
	</div>
</template>

<script>
import axios from 'axios'
import {base,uploads} from '../../../var.js'

export default {
	name: 'FeedComment',
	props: ['idre', 'logged'],
	data() {
		return {
			reposts: [],
			btnMais: false,
			mais: 3,
			uploads: uploads
		}
	},
	methods: {
		async getComm(){
			const req = await axios.post(base+'getItens/feedcomment', {
				id: this.idre
			});
			this.reposts = req.data.reposts
			if(req.data.total < this.mais){
				this.btnMais = true;
			}
		},
		async getMais(){
			let maisb = this.mais + 3;
			const req = await axios.post(base+'getItens/feed', {
				limit: maisb
			})
			this.repost = req.data.reposts
			if(req.data.total < this.mais){
				this.btnMais = true;
			}
		},
		async setDenun(id) {
			const den = confirm("Tem certeza?");
			if (den) {
				const req = await axios.post(base+'getItens/denuncia', {
					id: id,
					comm: true
				})
				this.textA = req.data.text
			}else {
				this.textA = ''
			}
			setTimeout(() => {
				this.textA = ''
			}, 3000);
		}
	},
	mounted(){
		this.getComm();
		setInterval(() => {
			this.getComm();
		}, 60000);
	}
}
</script>

<style scoped>
.commentsfeed {
	width: 90%;
	float: left;
	margin-left: 10%;
}
.commentsfeed .comment {
	position: relative;
	float: left;
	width: 100%;
	height: auto;
	top: 10px;
}

.commentsfeed .comment .topr .avatar {
	float: left;
	width: 50px;
	height: 50px;
	background-position: center;
	background-size: cover;
	border-radius: 4px;
}

.commentsfeed .comment .topr .autor,
.commentsfeed .comment .topr .date {
	float: left;
	width: 90%;
	height: 20px;
	margin: 4px 0 0 10px;
	font-size: 13px;
	color: var(--color2);
	line-height: 20px;
}
.content .box .topF .date {margin: 0px 0 0 8px;}


.commentsfeed .comment .contentr {
	float: left;
	width: 100%;
	height: auto;
	color: var(--color2);
	font-size: 13px;
	margin-top: 10px;
	border-bottom: 1px solid rgba(0,0,0,.08);
	padding: 10px 0;
}
.commentsfeed .comment:last-of-type .contentr {border:0}

.commentsfeed .comment .contentr img {
	position: relative;
	float: left;
	margin: 0 auto;
	width: 100%;
	max-width: 100%;
	margin: 6px 0;
	border-radius: 4px;
}
</style>