<template>
    <div class="item last mobs">
        <div class="top">
            <div class="left">
				<div class="icon"></div>
				<div class="title">Últimos <span>Mobis</span></div>
			</div>
        </div>
        <div class="content">
            <div class="boxItem tip" v-for="m in mobs" :key="m.id" :style="{backgroundImage: 'url(https://images.habblive.net/BETA/icons_habblive_atlanta/'+m.classname+'.png)'}">
                <MyTip :title="m.name" top="-100%"/>
            </div>
        </div>
        <div class="bottom">
			<div class="left">
				<input type="text" name="search" placeholder="Pesquisar..." v-model="this.search" @change="getMob(this.limit)">
				<i class="fa-solid fa-magnifying-glass"></i>
			</div>
			<div class="actions">
				<button :disabled='disableP == true' @click="getMob(this.limit, this.prev)"><i class="fa-solid fa-angle-left"></i></button>
				<button :disabled='disableN == true' @click="getMob(this.limit, this.next)"><i class="fa-solid fa-angle-right"></i></button>
			</div>
		</div>
    </div>
</template>

<script>
import MyTip from '../usefulness/tooltip.vue'
import axios from 'axios'

export default {
    name: 'LastMobs',
    components: {
        MyTip
    },
    data() {
        return {
            mobs: [],
            search: '',
            disableP: false,
            disableN: false,
            prev: 0,
            next: 0,
            limit: 21
        }
    },
    methods:{
        async getMob(l, pag=1) {
            let searchsql = ''
            if(this.search != ''){
                searchsql = '?search='+this.search
            }
            const req = await axios.get('https://api.pauloferraco.com.br/v3/habblive_mobs/'+l+'/'+pag+searchsql)
            this.mobs = req.data.mobs
            this.prev = req.data.prev
            this.next = req.data.next

            if(req.data.prev == 0){this.disableP = true;}else {this.disableP = false;}
			if(req.data.next > req.data.total){this.disableN = true;}else {this.disableN = false;}
        }
    },
    mounted(){
        this.getMob(this.limit)
    }
}
</script>

<style scoped>
.last {
    width: calc(33% - 8px);
}

.last .content {
    position: relative;
    float: left;
    width: 100%;
    height: auto;
    background-color: #0b1940;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    padding: 4px;
    z-index: 0;
}

.last .boxItem {
    float: left;
    position: relative;
    width: 46px;
    height: 46px;
    background-color: rgba(0, 0, 0, 0.1);
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 4px;
    margin: 4px;
}


.last .boxItem {
    float: left;
    position: relative;
    width: 46px;
    height: 46px;
    background-color: rgba(0, 0, 0, 0.1);
    background-position: center;
    border-radius: 4px;
    margin: 4px;
}


.last .bottom {
	position: relative;
	float: left;
	width: 100%;
	height: 40px;
	display: flex;
	justify-content: space-between;
	margin-top: 10px;
}

.last .bottom .left {width: 55%;}

.last .bottom .left input {
	width: 100%;
	height: 100%;
	background-color: #0b1940;
	border-radius: 4px;
	border: 1px solid #0b1940;
	padding: 0 10px 0 30px;
	font-size: 13px;
    color: #fff;
}

.last .bottom .left i {
	position: absolute;
	top: 5px;
	left: 2px;
	width: 30px;
	height: 30px;
	line-height: 30px;
	text-align: center;
	font-size: 14px;
	color: #fff;
}

.last .bottom .actions button {
	float: left;
	width: 70px;
	height: 40px;
	background-color: #0b1940;
	border: 1px solid rgba(0,0,0,.08);
	border-radius: 4px;
	color: rgba(0,0,0,.5);
	font-size: 13px;
	margin-right: 6px;
	cursor: pointer;
	transition: all .4s linear;
}
.last .bottom .actions button:hover {background-color: var(--color1);color: #fff;}

</style>